import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper";
import { BsCheckCircleFill, BsXCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import "swiper/css";
import Faq from "../components/Home/Faq";
import star from "../assets/star.png";
import circle from "../assets/circle.png";
import plus from "../assets/plus.png";
import star2 from "../assets/star-2.png";

// function importAll(r) {
//   return r.keys().map(r);
// }
// const images = importAll(
//   require.context("../assets/clients", false, /\.(png|jpe?g|svg)$/)
// );

function Pricing() {
  const { t } = useTranslation();

  return (
    <>
      <div className="lg:container mx-auto p-6 md:p-10">
        <div className="relative">
          <h2 className="text-center font-black xl:px-96 xl:py-16">
            {t("priceTitle")}
          </h2>
          <img
            src={star}
            className="w-[31px] h-[38px] xl:absolute xl:top-[10%] xl:left-[20%] hidden xl:block"
            alt="img"
          />
          <img
            src={plus}
            className="xl:absolute xl:top-[50%] xl:left-[15%] hidden xl:block"
            alt="img"
          />
          <img
            src={star}
            className="w-[18px] h-[25px] xl:absolute xl:top-[30%] xl:right-[20%] hidden xl:block"
            alt="img"
          />
          <img
            src={circle}
            className="xl:absolute xl:top-[70%] xl:right-[25%] hidden xl:block"
            alt="img"
          />
        </div>
        <div className="w-full md:flex md:justify-center md:items-stretch mt-6 text-center space-y-6 md:space-y-0">
          {t("price", { returnObjects: true }).map((e) => {
            return (
              <div
                className="bg-white px-10 py-6 shadow-lg rounded-[30px] space-y-4 font-semibold flex flex-col card md:w-[350px] lg:hover:scale-105 even:md:border-x-2"
                key={e.id}
              >
                <h2 className="text-[20px] font-extrabold">{e.type}</h2>
                <h1 className="text-[36px] text-bold">{e.price}</h1>
                <div className="flex flex-col flex-1 space-y-4">
                  <h3 className="text-[14px]">{e.userCount}</h3>
                  <p className="text-[11px] normal-case">{e.description}</p>
                </div>
                {e.id === 0 ? <Link
                  to="/signup"
                  className="uppercase px-6 py-2 rounded-[50px]"
                >
                  {e.button}
                </Link> : <a href={e.link} target="_blank" rel="noreferrer" className="uppercase px-6 py-2 rounded-[50px]">{e.button}</a>} 
              </div>
            );
          })}
        </div>
        <h3 className="p-4 my-8 lg:my-12 text-center normal-case text-[18px]">
          {t("priceTitle2")}
        </h3>
        <div className="overflow-x-auto 2xl:px-[210px] xl:drop-shadow-lg">
          <table className=" bg-white w-full border-separate border-spacing-x-3 lg:border-spacing-x-6 lg:py-10 lg:px-20 rounded-[30px] normal-case">
            <thead>
              <tr>
                {t("tableHd", { returnObjects: true }).map((e, i) => (
                  <th key={i} className='font-extrabold'>{e}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {t("tableDt", { returnObjects: true }).map((e, i) => (
                <tr key={i}>
                  {e.map((j, i) => {
                    if (j === "+")
                      return (
                        <td key={i}>
                          <span className="flex justify-center text-currentColor">
                            <BsCheckCircleFill size={16} />
                          </span>
                        </td>
                      );
                    else if (j === "-")
                      return (
                        <td key={i}>
                          <span className="flex justify-center">
                            <BsXCircle color="grey" size={16} />
                          </span>
                        </td>
                      );
                    else return <td key={i} className='text-[14px] font-medium tracking-tight'>{j}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="lg:container flex justify-center relative lg:px-9 2xl:px-0">
        <div className="bg-[#133A62] md:rounded-none 2xl:w-[68%] lg:rounded-[30px] flex flex-col lg:flex-row text-white text-[12px] md:text-[14px] items-center justify-center lg:justify-evenly py-16 px-6 lg:px-12 lg:mt-16">
          <div className="lg:w-1/2 text-center normal-case text-[#99FFF9]">{t("priceCTA")}</div>
          <img
            src={star2}
            className="w-[20px] h-[25px] xl:absolute xl:top-[30%] xl:left-[18%] rotate-[-40deg] hidden xl:block"
            alt="img"
          />
          <img
            src={star2}
            className="w-[16px] h-[19px] xl:absolute xl:top-[35%] xl:left-[54%] hidden xl:block"
            alt="img"
          />
          <img
            src={star2}
            className="w-[23px] h-[27px] xl:absolute xl:top-[35%] xl:right-[20%] hidden xl:block"
            alt="img"
          />
          <img
            src={star2}
            className="w-[16px] h-[19px] xl:absolute xl:top-[73%] xl:left-[22%] rotate-[-40deg] hidden xl:block"
            alt="img"
          />
          <a href="mailto:info@chatbot.mn" rel="noreferrer" target="_blank" className="bg-[#01A5F9] mt-6 lg:mt-0 uppercase px-6 py-2 rounded-[50px] hover:scale-[1.02] hover:text-white text-center">{t("button.priceCTA")}</a>
        </div>
        </div>
      <div className="lg:container p-6 md:p-10">
        <h2 className="text-center mt-6 lg:px-36 font-black">
          {t("priceComment")}
        </h2>
        <div className="2xl:px-[210px]" id="faq">
          <Swiper
            spaceBetween={5}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            loop
            autoplay={{delay:5000, disableOnInteraction: true, pauseOnMouseEnter: true}}
            modules={[EffectFade, Autoplay]}
          >
            {t("priceCommentList", { returnObjects: true }).map((e) => {
              return (
                <SwiperSlide key={e.id} className="py-10">
                  <div className="p-6 rounded-lg xl:drop-shadow-lg space-y-3 bg-white border h-full lg:hover:-translate-y-1">
                    <img
                      src={e.img}
                      className={`${e.id === 2 ? "p-4 h-20" : "h-20"}`}
                      alt=""
                    />
                    <div className="flex flex-col space-y-2">
                      <p className="text-[12px] font-semibold text-black normal-case text-justify tracking-tight">
                        {e.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <Faq />
    </>
  );
}

export default Pricing;
