import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "swiper/css";
import { useTranslation } from "react-i18next";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../assets/clients", false, /\.(png|jpe?g|svg)$/)
);

function SecondDiv() {
  const [counter, setCounter] = useState(false);
  const [stats, setStats] = useState();
  const { t } = useTranslation();

  const fetchStats = async () => {
    await fetch("https://chatbot.mn/api/stats/")
      .then((res) => res.json())
      .then((data) => setStats(data));
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <>
      <div className="bg-[#08315f]">
        <div className="flex justify-evenly text-center py-5 text-[8px] md:text-[12px] lg:text-[13px] lg:font-bold lg:container text-white ">
          <div>
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp
                  end={stats?.info.total_sender || 6676417}
                  redraw={true}
                  duration={0.8}
                  separator=","
                  className="text-[#99FFF9] font-bold text-[12px] md:text-[18px] lg:text-[32px]"
                ></CountUp>
              )}
              <div className="px-0 sm:px-6 normal-case">
                {t("heroStat.col1")}
              </div>
            </ScrollTrigger>
          </div>
          <div>
            <CountUp
              end={stats?.info.total_connected_bot || 13884}
              redraw={true}
              duration={0.7}
              separator=","
              className="text-[#99FFF9] font-bold text-[12px] md:text-[18px] lg:text-[32px]"
            ></CountUp>
            <div className="px- sm:px-6 normal-case">{t("heroStat.col2")}</div>
          </div>
          <div>
            <CountUp
              end={500}
              suffix="+"
              redraw={true}
              duration={1}
              className="text-[#99FFF9] font-bold text-[12px] md:text-[18px] lg:text-[32px]"
            ></CountUp>
            <div className="px-0 sm:px-6 normal-case">{t("heroStat.col3")}</div>
          </div>
        </div>
      </div>
      <div className="lg:container">
        <h2 className="font-black p-6 lg:pt-20 xl:pt-[127px] xl:pb-[148px] md:px-10 lg:px-48">
          {t("hero.companies")}
        </h2>

        <div className="px-6 lg:pb-10 xl:p-0 xl:pb-[159px]">
          <Swiper
            spaceBetween={0}
            slidesPerView={6}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
            loop
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            modules={[EffectFade, Autoplay]}
          >
            {images.map((e, i) => {
              return (
                <SwiperSlide
                  key={i}
                  className="flex items-center justify-center"
                >
                  <img
                    src={e}
                    className="scale-75 grayscale hover:grayscale-0"
                    alt="img"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default SecondDiv;
