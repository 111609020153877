import { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useTranslation } from "react-i18next";

function Faq() {
  const { t } = useTranslation();
  let faq = t("faq", { returnObjects: true });
  const [checkedState, setCheckedState] = useState(
    new Array(faq.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  //   const handleChange = () => {
  //     setIsChecked(!isChecked)
  //   };

  return (
    <div className="p-6 lg:container">
      <h2 className="font-black pb-6 text-[#00272B]">{t("faqHeader")}</h2>
      {faq.map((e) => {
        return (
          <ul
            key={e.id}
            onClick={() => handleOnChange(e.id)}
            className="rounded-md w-full lg:w-[70%] my-4 drop-shadow-md bg-white md:mx-auto md:w-4/5 hover:-translate-y-1 hover:shadow-xl transition ease-in-out delay-150 duration-300"
          >
            <li className="flex p-6 justify-between">
              <h2 className="text-[#00272B] text-[14px] lg:text-[16px] font-bold text-left">
                {e.title}
              </h2>
              <input
                type="checkbox"
                checked={checkedState[e.id]}
                onChange={handleOnChange}
                className="hidden"
              />
              {checkedState[e.id] ? (
                <MdKeyboardArrowUp size={20} />
              ) : (
                <MdKeyboardArrowDown size={20} />
              )}
            </li>
            {checkedState[e.id] && (
              <p className="px-6 pb-6 whitespace-pre-line text-[#6F6D71] font-medium normal-case lg:text-[16px] tracking-tight">
                {e.description}
              </p>
            )}
          </ul>
        );
      })}
    </div>
  );
}

export default Faq;
