import React from "react";
import man from "../../assets/man.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import star from "../../assets/star.png";
import plus from "../../assets/plus.png";

function VerticalDiv() {
  const { t } = useTranslation();
  return (
    <div className="lg:bg-[#F1F1F1] lg:flex lg:justify-center lg:relative">
      <div className="p-6 lg:px-24 overflow-hidden lg:bg-white lg:align-top lg:inline-block lg:container lg:relative">
        <h2 className="lg:p-8 font-black">{t("vertTitle")}</h2>
        {t("vertData", { returnObjects: true }).map((e) => {
          let xdir;
          if (e.id % 2 === 0) {
            xdir = -200;
          } else {
            xdir = 200;
          }
          return (
            <div
              key={e.id}
              className="flex flex-col even:md:flex-row odd:md:flex-row-reverse items-center mt-8 normal-case text-customBlue text-[24px] md:mx-10 xl:mx-28 lg:justify-between tracking-tight"
            >
              <div className="flex justify-center md:w-1/2">
                <motion.img
                  initial={{ x: xdir }}
                  whileInView={{ x: 0 }}
                  transition={{ duration: 1 }}
                  src={e.img}
                  alt=""
                  loading="lazy"
                  className="vert"
                />
              </div>
              <div className="p-6 space-y-2 lg:flex-initial md:w-1/2 lg:min-w-[300px] xl:px-28">
                <h1 className="text-[16px] font-black">{e.title}</h1>
                <p className="text-[14px] text-justify lg:pt-6 text-[#6F6D71]">
                  {e.description}
                </p>
              </div>
              <img
                src={star}
                className="hidden lg:block w-[31px] h-[38px] absolute lg:top-[5%] lg:left-[5%]"
                alt="img"
              />
              <img
                src={plus}
                className="hidden lg:block lg:absolute lg:bottom-[10%] lg:right-[5%]"
                alt="img"
              />
            </div>
          );
        })}
      </div>
      <img
        src={man}
        className="hidden absolute lg:block h-36 bottom-0 left-0"
        alt="img"
      />
    </div>
  );
}

export default VerticalDiv;
