import React from "react";
import ReactDOM from "react-dom/client";
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import {motion} from 'framer-motion'
import {mn} from './assets/locales/mn'
import {kz} from './assets/locales/kz'
import {en} from './assets/locales/en'
import {ru} from './assets/locales/ru'
// import img from './assets/mobile-logo.png'
import "./index.css";
import App from "./App";

// const loading = (
//   <div className="w-screen h-screen flex items-center justify-center">
//     <motion.img 
//     src={img} 
//     className='w-18 h-24'
//     animate={{
//       rotate: 360,
//     }}
//     transition={{repeat: Infinity, duration: 1}}/>
//     </div>
// );

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
  debug: false,
  fallbackLng: 'mn',
  lng: 'mn',
  load: 'languageOnly',
  whitelist: ['mn', 'en', 'ru', 'kz'],
  detection: {
    order: ['localstorage','session','cookie'],
    lookupFromPathIndex: 0,
    checkWhitelist: true
  },
  resources: {
    mn: {
      translation: mn,
    },
    en: {
      translation: en,
    },
    kz: {
      translation: kz,
    },
    ru: {
      translation: ru,
    },
  } 
  });


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
