import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useLayoutEffect } from "react";
import Navbar from "./components/Navbar";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Product from "./components/Product";
// import Error from "./components/Error";
import Home from "./components/Home";
// import Resources from "./components/Resources";
import About from "./components/About";
import Pricing from "./components/Pricing";
import Tutorials from "./components/Tutorials";
import Tutorial from "./components/Tutorial";
import Restore from "./components/Restore";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Router>
      <Wrapper>
        {/* <Navbar/> */}
        <Routes>
            <Route path="/" element={<Navbar />}>
            <Route path="" element={<Home />} />
            <Route path="product" element={<Product />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="tutorials" element={<Tutorials />} />
            <Route path="tutorials/:id" element={<Tutorial />} />
            {/* <Route path="resources" element={<Resources />} /> */}
            <Route path="about" element={<About />} />
            {/* <Route path="*" element={<Error />} /> */}
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            </Route>

          <Route path="signin" element={<Signin />} />
          <Route path="signup" element={<Signup />} />
          <Route path="restore" element={<Restore />} />
          {/* <Route path="caro" element={<VideoDesk />} /> */}
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
