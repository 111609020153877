import { motion } from "framer-motion";
import iphone from "../../assets/iphone2.png";
import muugii from "../../assets/muugii.png";
import { useTranslation } from "react-i18next";
import fb from "../../assets/fb.png";
import circle from "../../assets/circle.png";
import mpic from "../../assets/mpic.png";
import cloud from "../../assets/cloud.png";

function Hero() {
  const { t } = useTranslation();
  return (
    <div className="bg-white lg:bg-[#F1F1F1]">
      <div className="flex flex-col-reverse md:flex-row justify-center lg:container lg:h-[530px] 2xl:h-[calc(977px-103.1px)]">
        <div className="hidden lg:flex lg:justify-center items-center lg:items-start flex-1 ">
          <div className="bg-white lg:w-[260px] lg:h-[480px] 2xl:w-[353px] 2xl:h-[610px] rounded-b-[107px] relative">
            <motion.img
              src={muugii}
              initial={{ y: -200, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              className="lg:max-w-[320px] lg:h-[370px] 2xl:max-w-[443px] 2xl:h-[473px] mt-9 lg:mt-0 lg:absolute lg:top-[24%] lg:right-0"
              alt=""
              loading="lazy"
            />
            <img
              src={circle}
              className="lg:absolute lg:top-[45%] lg:left-[-16%]"
              alt="star"
            />
            <img
              src={cloud}
              className="lg:absolute lg:top-[15%] lg:left-[95%] lg:rotate-[15deg] lg:scale-75"
              alt="sun"
            />
            <img
              src={fb}
              className="w-[65px] h-[72px] lg:absolute lg:top-[10%] lg:-left-2 rotate-[-15deg]"
              alt="fb"
            />
          </div>
        </div>
        <div className="flex-1 space-y-3 p-6 text-center text-customBlue mt-2 self-center 2xl:self-start 2xl:pt-48">
          <h2 className="font-extrabold text-[rgba(3,21,85,0.7)] normal-case">
            {t("hero.h2")}
          </h2>
          <h1 className="font-black leading-tight">{t("hero.h1")}</h1>
          <p className="text-[14px] lg:text-[0.85vw] xl:text-[16px] lg:font-medium md:px-10 text-[rgba(3,21,85,0.7)] normal-case">
            {t("hero.h3")}
          </p>
          <button className="bg-[#4095EE] button border-[#4095EE] text-white font-bold scale-75 md:scale-100 ">
            <a
              href={t("hero.downloadLink")}
              target="_blank"
              className="hover:text-white"
              rel="noreferrer"
              download
            >
              {t("button.download")}
            </a>
          </button>
        </div>
        <div className="flex justify-center items-center lg:items-start flex-1 ">
          <div className="bg-white w-[260px] h-[520px] lg:w-[260px] lg:h-[480px] 2xl:w-[353px] 2xl:h-[610px] lg:rounded-b-[60px] 2xl:rounded-b-[97px] relative">
            <motion.img
              src={iphone}
              initial={{ y: -200, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              className="w-[260px] h-[480px] lg:w-[240px] lg:h-[415px] 2xl:w-[287px] 2xl:h-[500px] mt-4 lg:mt-0 lg:absolute lg:top-[15%] lg:left-[4%] 2xl:top-[20%] 2xl:left-[9%]"
              alt=""
              loading="lazy"
            />
            <img
              src={mpic}
              className="lg:absolute lg:top-[10%] lg:-right-8 hidden lg:block"
              alt="img"
            />
            <img
              src={circle}
              className="lg:absolute lg:top-[10%] lg:-left-20 hidden lg:block"
              alt="img"
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              className="talkbubbletl hidden top-[38%] xl:flex xl:left-[-40px] normal-case"
            >
              Get Started
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              className="talkbubblebl hidden top-[53%] xl:flex xl:left-[-100px]"
            >
              {t("hero.bubble1")}
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              className="talkbubbletr hidden top-[63%] xl:flex xl:right-[-50px] normal-case"
            >
              {t("hero.bubble2")}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
