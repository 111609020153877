import { Link, Outlet } from "react-router-dom";
import { Turn as Hamburger } from "hamburger-react";
import { useState } from "react";
import logo from "../assets/logo.png";
import { motion, AnimatePresence } from "framer-motion";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useTranslation } from "react-i18next";
import fetchWithTimeout from "../utils/fetchApi";
import { useEffect } from "react";
import Footer from "./Footer";
import FooterWithImg from "./Home/FooterWithImg";
import { useLocation } from "react-router-dom";

function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const [showBolomj, setShowBolomj] = useState(false);
  const [showDD, setDD] = useState(false);
  const { t, i18n } = useTranslation();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();

  const langs = ["mn", "en", "kz", "ru"];
  const resources = t("navFeatures", { returnObjects: true });

  const handleChildClick = (e) => {
    e.stopPropagation();
    setDD(!showDD);
  };

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  useEffect(() => {
    const checkAuth = async () => {
      const res = await fetchWithTimeout("/auth/login", {
        method: "POST",
        body: {},
      });

      if (res?.result?.code === 1000) {
        setIsAuthorized(true);
      }
    };

    checkAuth();
  }, []);

  // const wrapperRef = useRef(null)

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       setOpen(false)
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [wrapperRef]);

  return (
    <>
      <nav className="shadow w-full flex items-center justify-between bg-white px-2 md:px-10 lg:px-20 2xl:pl-[239px] 2xl:pr-[265px] sticky top-0 z-50 text-[0.75vw]">
        <div className="flex items-center">
          <Link
            to="/"
            className="w-[180px] md:w-[200px] xl:w-[230px] lg:mr-12"
            onClick={() => {
              setOpen(false);
              scrollToTop();
            }}
          >
            <img src={logo} alt="logo" />
          </Link>
          {/* Widescreen */}
          <ul className="hidden lg:flex space-x-16 font-extrabold mt-4">
            <li className="lg:h-[60px] xl:h-[90px] flex items-center">
              <Link to="/product">{t("nav.li1")}</Link>
            </li>
            <li className="lg:h-[60px] xl:h-[90px] flex items-center">
              <Link to="/pricing">{t("nav.li2")}</Link>
            </li>
            <li
              className="lg:h-[61px] xl:h-[91px] flex items-center"
              onMouseOver={() => setShowBolomj(true)}
              onMouseOut={() => setShowBolomj(false)}
            >
              {/* <Link to="/resources"> */}
              {t("nav.li3")}
              {/* </Link> */}
              {showBolomj ? (
                <MdKeyboardArrowUp size={"1vw"} />
              ) : (
                <MdKeyboardArrowDown size={"1vw"} />
              )}
              {showBolomj && (
                <div className="grid grid-cols-1 gap-2 absolute lg:top-[73.1px] xl:top-[103.1px] left-[39%] shadow-md bg-white rounded-xl p-6">
                  {resources.map((e) => (
                    <div key={e.id} className="px-2">
                      {/* {e.id === 0 || e.id === 5 ? (<a href={e.route} className="flex">{e.type}</a>) : <Link to={e.route} className="flex">{e.type}</Link>} */}
                      {e.id !== 3 ? (
                        <a
                          href={e.route}
                          className="flex cursor-pointer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {e.type}
                        </a>
                      ) : (
                        <Link to={e.route}>{e.type}</Link>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </li>
          </ul>
        </div>
        <ul className="hidden lg:flex mt-3 space-x-6">
          <select
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
            defaultValue={localStorage.getItem("i18nextLng")}
            className="focus:outline-none"
          >
            {langs.map((e, i) => (
              <option value={e} key={i}>
                {e.toUpperCase()}
              </option>
            ))}
          </select>
          {!isAuthorized ? (
            <>
              <li>
                <Link
                  to="signin"
                  className="border-2 px-6 py-2 rounded-3xl border-customBlue font-bold hover:text-white hover:bg-customBlue"
                >
                  {t("button.signin")}
                </Link>
              </li>
              <li>
                <Link
                  to="/signup"
                  className="border-2 px-4 py-2 rounded-3xl text-white font-bold border-customPink bg-customPink hover:text-customBlue hover:bg-white"
                >
                  {t("button.signup")}
                </Link>
              </li>
            </>
          ) : (
            <div className="flex items-center space-x-2">
              <div className="border-2 px-6 py-1 rounded-3xl border-customBlue font-bold hover:text-white hover:bg-customBlue">
                <a href="/dashboard/" className="hover:text-white">
                  {t("button.dashboard")}
                </a>
              </div>
            </div>
          )}
        </ul>

        {/* Hamburger menu */}

        <div className="flex lg:hidden">
          <select
            className="text-[12px] focus:outline-none"
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
            defaultValue={localStorage.getItem("i18nextLng")}
          >
            {langs.map((e, i) => (
              <option value={e} key={i}>
                {e.toUpperCase()}
              </option>
            ))}
          </select>
          <Hamburger toggled={isOpen} toggle={setOpen} size={25} />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.ul
              // ref={wrapperRef}
              onClick={() => setOpen(!isOpen)}
              className="text-[16px] text-center font-semibold space-y-6 p-6 absolute top-[63px] md:top-[71.6px] left-0 w-full bg-white h-screen"
              initial={{ marginTop: -10, opacity: 0 }}
              animate={{ marginTop: 0, opacity: 1 }}
              exit={{ marginTop: -10, opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              <li>
                <Link to="/product" className="flex">
                  {t("nav.li1")}
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="flex">
                  {t("nav.li2")}
                </Link>
              </li>
              <li
                // uncomment class for DD
                className="flex justify-between"
                onClick={(e) => {
                  handleChildClick(e);
                }}
              >
                {/* <Link to="/resources" className="flex"> */}
                {t("nav.li3")}
                {/* </Link> */}
                {/* Dropdown ашиглах бол коммент арилгана */}
                <input type="checkbox" className="hidden" />
                {showDD ? (
                  <MdKeyboardArrowUp size={20} />
                ) : (
                  <MdKeyboardArrowDown size={20} />
                )}
              </li>
              {showDD && (
                <ul className="space-y-6 p-6">
                  {resources.map((e) => (
                    <li key={e.id}>
                      {e.id !== 3 ? (
                        <a href={e.route} className="flex">
                          {e.type}
                        </a>
                      ) : (
                        <Link to={e.route} className="flex">
                          {e.type}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {!isAuthorized ? (
                <>
                  <li>
                    <Link
                      to="/signup"
                      className="rounded-3xl px-4 py-2  bg-customPink text-white"
                    >
                      {t("button.signup")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/signin">{t("button.signin")}</Link>
                  </li>
                </>
              ) : (
                <div className="flex flex-col justify-center space-y-2 md:px-36">
                  <div className="border-2 py-1 rounded-3xl border-customBlue font-bold hover:text-white hover:bg-customBlue">
                    <a href="/dashboard/" className="hover:text-white">
                      {t("button.dashboard")}
                    </a>
                  </div>
                </div>
              )}
            </motion.ul>
          )}
        </AnimatePresence>
      </nav>
      <Outlet />
      {(() => {
        switch (location.pathname) {
          case "/":
            return <FooterWithImg />;
          case "/terms":
          case "/privacy":
            return null;
          default:
            return <Footer />;
        }
      })()}
    </>
  );
}

export default Navbar;
