import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import Alert from "./Alert";
import fetchWithTimeout from "../utils/fetchApi";

function Signin() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [alert, setAlert] = useState({
    state: false,
    message: "",
    bg: "",
    text: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const login = async () => {
    try {
      const result = await fetchWithTimeout("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (result.result.code === 1000) {
        setAlert((prev) => ({
          ...prev,
          state: true,
          message: "Амжилттай нэвтэрлээ!",
          bg: "bg-green-100 ",
          text: "text-green-700",
        }));
        window.location.href = "/dashboard/";
        localStorage.removeItem("newUser");
      } else
        setAlert((prev) => ({
          ...prev,
          state: true,
          message: result.result.message,
          bg: "bg-red-100",
          text: "text-red-700",
        }));
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="lg:container mx-auto px-2">
      <div className="2xl:pl-[40px] 2xl:mt-3">
        <Link to="/">
          <img
            src={logo}
            className="w-[180px] md:w-[200px] xl:w-[230px]"
            alt=""
          />
        </Link>
      </div>
      <div className="w-full h-[calc(100vh-64.5px)] md:h-[calc(100vh-103.1px)] lg:h-[calc(80vh-103.1px)] flex flex-col justify-center items-center">
        <h1 className="text-[20px] font-black text-center p-4 md:text-[24px] lg:text-[36px]">
          {t("button.signin")}
        </h1>
        <Alert
          message={alert.message}
          state={alert.state}
          bg={alert.bg}
          text={alert.text}
        />
        <form
          className="space-y-4 w-full md:w-[80%] lg:w-[33%] flex flex-col"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            placeholder={t("input.email")}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, email: e.target.value }))
            }
            className="h-10 rounded-[20px] p-6 border border-solid border-slate-400 focus:outline-none focus:border-customPink lg:h-16"
          />
          <input
            placeholder={t("input.pwd")}
            type="password"
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, password: e.target.value }))
            }
            className="h-10 rounded-[20px] p-6 border border-solid border-slate-400 focus:outline-none focus:border-customPink lg:h-16 placeholder:font-normal font-black"
          />
          <div className="flex justify-between items-center w-full text-[12px] md:text-[14px]">
            <div className="flex items-center font-semibold">
              <input type="checkbox" className="mr-2" />
              {t("input.remember")}
            </div>
            <Link to="/restore" className="font-semibold">
              {t("input.forgot")}
            </Link>
          </div>
          <div className="flex flex-col md:items-center space-y-4 text-white font-bold w-full text-[14px]">
            <button className="bg-customPink h-10 md:w-[60%] lg:w-full">
              {t("button.signin")}
            </button>
            <a
              href={`${process.env.REACT_APP_URL}/facebook/connect`}
              className="fb"
            >
              {t("input.button")}
              {/* <button className="bg-customPink h-10 md:w-[60%] lg:w-full" >
              Facebook-р нэвтрэх
            </button> */}
            </a>
          </div>
        </form>
        <p className="text-slate-400 text-[10px] font-bold w-full text-center p-6 lg:text-[12px]">
          {t("input.signupif")} <br className="md:hidden" />
          <Link to="/signup" className="text-customPink hover:text-customBlue">
            {t("input.signupifLink")}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Signin;
