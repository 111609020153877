import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "./Alert";
import fetchWithTimeout from "../utils/fetchApi";

function Signup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const regexCheck = (email) => {
    if (!email) return false;
    else return emailRegex.test(email);
  };

  const [warning, setWarning] = useState({
    email: {
      isActive: false,
    },
    password: {
      isActive: false,
    },
    lastname: {
      isActive: false,
    },
    firstname: {
      isActive: false,
    },
    phone: {
      isActive: false,
    },
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    lastname: "",
    firstname: "",
    phone: "",
  });

  const [alert, setAlert] = useState({
    state: false,
    message: "",
    bg: "",
    text: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (regexCheck(formData.email) === false) {
      setWarning((prev) => ({ ...prev, email: { isActive: true } }));
      return;
    }
    if (formData.firstname === "") {
      setWarning((prev) => ({ ...prev, firstname: { isActive: true } }));
    }
    if (formData.lastname === "") {
      setWarning((prev) => ({ ...prev, lastname: { isActive: true } }));
    }
    if (formData.phone === "") {
      setWarning((prev) => ({ ...prev, phone: { isActive: true } }));
    }
    if (formData.password === "") {
      setWarning((prev) => ({ ...prev, password: { isActive: true } }));
    }
    register();
  };

  const handleChange = (e) => {
    e.target.value === ""
      ? setWarning((prev) => ({ ...prev, [e.target.name]: { isActive: true } }))
      : setWarning((prev) => ({
          ...prev,
          [e.target.name]: { isActive: false },
        }));
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const register = async () => {
    try {
      const result = await fetchWithTimeout("/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (result.result.code === 1000) {
        switch (localStorage.getItem("newUser")) {
          case "1":
            //Ecommerce
            window.location.href =
              "https://www.messenger.com/t/105586168752112";
            break;
          case "2":
            //Health
            window.location.href =
              "https://www.messenger.com/t/104653705897272";
            break;
          case "3":
            //Restaurant
            window.location.href =
              "https://www.messenger.com/t/115249621509971";
            break;
          case "4":
            //Real state
            window.location.href =
              "https://www.messenger.com/t/103318735627287";
            break;
          case "5":
            //Education
            window.location.href =
              "https://www.messenger.com/t/104721671801417";
            break;
          default:
            navigate("/signin");
            break;
        }
      } else
        setAlert((prev) => ({
          ...prev,
          state: true,
          message: result.result.message,
          bg: "bg-red-100",
          text: "text-red-700",
        }));
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="md:container mx-auto px-2">
      <div className="2xl:pl-[40px] 2xl:mt-3">
        <Link to="/">
          <img
            src={logo}
            className="w-[180px] md:w-[200px] xl:w-[230px]"
            alt=""
          />
        </Link>
      </div>
      <div className="w-full h-[calc(100vh-64.5px)] md:h-[calc(100vh-103.1px)] lg:h-full flex flex-col justify-center items-center">
        <h1 className="text-[20px] font-black text-center p-4 md:text-[24px] lg:text-[36px]">
          {t("button.signup")}
        </h1>
        <Alert
          message={
            alert.message === "invalid parameter"
              ? "Дутуу талбарыг бөглөнө үү"
              : alert.message
          }
          state={alert.state}
          bg={alert.bg}
          text={alert.text}
        />
        <form
          className="sign space-y-4 w-full md:w-[80%] lg:w-[33%] flex flex-col"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            name="email"
            placeholder={t("input.email")}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {warning.email.isActive && <label>{t("input.label1")}</label>}
          <input
            placeholder={t("input.pwd")}
            name="password"
            onChange={(e) => {
              handleChange(e);
            }}
            className="placeholder:font-normal font-black"
            type="password"
          />
          {warning.password.isActive && <label>{t("input.label2")}</label>}
          <input
            placeholder={t("input.lName")}
            name="lastname"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {warning.lastname.isActive && <label>{t("input.label3")}</label>}
          <input
            placeholder={t("input.fName")}
            name="firstname"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {warning.firstname.isActive && <label>{t("input.label4")}</label>}
          <input
            name="phone"
            placeholder={t("input.phone")}
            onChange={(e) => {
              handleChange(e);
            }}
            maxLength={8}
          />
          {warning.phone.isActive && <label>{t("input.label5")}</label>}
          <div className="flex flex-col md:items-center space-y-4 text-white font-bold pt-2 w-full text-[14px]">
            <button className="bg-customPink h-10 md:w-[60%] lg:w-full">
              {t("button.signup")}
            </button>
            {localStorage.getItem("newUser") ? null : (
              <a
                className="fb"
                href={`${process.env.REACT_APP_URL}/facebook/connect`}
              >
                {t("input.button")}
              </a>
            )}
          </div>
        </form>
        <div className="flex flex-col w-full space-y-4 font-bold pt-4">
          <Link
            to="/signin"
            className="text-slate-400 text-[12px] font-bold text-center"
          >
            {t("input.signinif")}
          </Link>
          <p className="text-slate-400 text-[10px] font-semibold text-center">
            {t("input.acceptTerm")} <br />
            <Link to="/terms" className="text-customPink hover:text-customBlue">
              {t("input.term")}
            </Link>
            ,{" "}
            <Link
              to="/privacy"
              className="text-customPink hover:text-customBlue"
            >
              {t("input.privacy")}
            </Link>
            <br />
            {t("input.acceptTerm2")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signup;
