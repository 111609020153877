import vert1 from "../vert-1.png";
import vert2 from "../vert-2.png";
import vert3 from "../vert-3.png";
import vert4 from "../vert-4.png";
import grid1 from "../grid-1.png";
import grid2 from "../grid-2.png";
import grid3 from "../grid-3.png";
import grid4 from "../grid-4.png";
import grid5 from "../grid-5.png";
import grid6 from "../grid-6.png";
import grid7 from "../grid-7.png";
import grid8 from "../grid-8.png";
import emch from "../emch.png";
import pn from "../pn.png";
import itzone from "../itzone.png";
import ecommerce from "../ecommerce.png";
import education from "../education.png";
import hospital from "../hospital.png";
import realestate from "../realestate.png";
import restaurant from "../restaurant.png";

export const kz = {
  nav: {
    li1: "ҚЫЗМЕТ",
    li2: "Баға",
    li3: "Мүмкіндік",
  },
  navFeatures: [
    // {
    //   id: 0,
    //   type: "Блог",
    //   route: "/pricing",
    // },
    {
      id: 1,
      type: "Көмек",
      route: "https://www.messenger.com/t/308105179819485",
    },
    // {
    //   id: 2,
    //   type: "Нұсқау",
    //   route: "/",
    // },
    {
      id: 3,
      type: "Онлайн тренинг",
      route: "/tutorials",
    },
    // {
    //   id: 4,
    //   type: "Видео сабақ",
    //   route: "/",
    // },
    {
      id: 5,
      type: "Нұсқаулық",
      route: "https://help.chatbot.mn/",
    },
  ],
  hero: {
    h2: "СІЗДІҢ БИЗНЕС ҚҰРАЛДАРЫҢЫЗ",
    h1: "КЛИЕНТЕРІҢЗДІ ТҰТЫНУШЫ БОЛДЫРЫҢЫЗ",
    h3: "ӘРБІР АДАМҒА АРНАЛҒАН ИНТЕЛЕКТНЫЙ ЗЕРДЕМЕН БАЙЛАНЫСТЫРЫЛҒАН ВИРТУАЛДЫ КӨМЕКШІҢІЗ",
    companies:
      "Іс шарасына ЧАТБОТ қызметін қосып сәтті қолданып жатқан филиал үздіктері",
    bubble1: "Сәлеметсіз бе. Chatbot.mn автоматты чатботы жауап беруде.",
    bubble2: "Чатбот дегеніміз не?",
    downloadLink:
      "https://chatbot.mn/uploads/home/Taniltsuulga%20KAZAKH%20NEW%203.pdf",
  },
  vertTitle: "Чатбот маңызды маркетинг құралы",
  vertData: [
    {
      id: 0,
      title: "МАРКЕТИНГ ӘДІСТЕРІН КҮШЕЙТНІЗ ",
      description:
        "Чатбот маркетинг әдістерін пайдалану арқылы жаңа қолданушыларды тартып, оларды баптау, әріқарай сіздің компания және брендінің адал қолданушы болдырұға болады.",
      img: vert1,
    },
    {
      id: 1,
      title: "ҚЫЗМЕТІҢДІ ЖАҚСАРТЫП, ДАМЫТ",
      description:
        "Чатбот арқылы қолданушылар датасын жинауға болады және жиналған дата деректерін қалауыңзша талдап әрі қарай оның көмегі арқылы іс қызмет жақсарту, дамытуға болады.",
      img: vert4,
    },
    {
      id: 2,
      title: "ҚОЛДАНУШЫ ҚЫЗМЕТІН ТИІМДІ БОЛДЫР ",
      description:
        "Чатбот-арқылы қолданушылар шағым, пікірін алып, оларға қажетті ақпараттарын 24/7 сағат тұрақды жеткіз.",
      img: vert3,
    },
    {
      id: 3,
      title: "САТЫЛЫМ КІРІСІН ЖАҚСАРТ",
      description:
        "ДҮКЕН мәзіріне тауарыңзды орналастырып төлем, тауар тіркеү, қалдық, жетқізү деген сияқды керекді барлық жақтарын пайдаланып, онлайн сатуға болады.",
      img: vert2,
    },
  ],
  integration: "ІС ҚЫЗМЕТІНЕ ТҰРАҚТЫ ПАЙДАЛАНТЫН ҚҰРАЛДАРНЫЗҒА ҚОСЫҢЫЗ.",
  integrationDesc:
    "Технологиялар стегін қолданбалармен байланыстыру арқылы топ тиімділігін арттырып, тұтынушыларңызды бақытты болдырып, интерком пайдаланып көбірек істеңіз.",
  subscribeM:
    "Төмендегі бөлімде электрондық поштаңызды тіркеп, қызықты жаңалықтарды үнемі ала аласыз.",
  subscribeW:
    "Төмендегі бөлімде электрондық поштаңызды тіркеп қарым-қатынас маркетинг және технолгия саласының бағыты, чатбот дамыту мүмкіндіктер, жаңа өнімдер, қызметтер туралы қызықты жаңалықтарды үнемі ала аласыз.",
  priceTitle:
    "МЕКЕМЕ мақсаты, іс қызметіне байланыстыра отырып келескі жүйлерден таңдаузды жасаңыз.",
  price: [
    {
      id: 0,
      type: "PRO ПАКЕТІ",
      price: "40,021₸",
      userCount: "Қолданушы саны",
      description:
        "Жеке меншікді бизнес басқарушы, шағын және орта компаниялар үшін тұтынушылармен тиімді қарым-қатынас жасауға, қарым-қатынастарды басқаруға және дамытуға көмектесетін пакет.",
      button: "Тіркелү",
      link: "",
    },
    {
      id: 1,
      type: "ENTERPRICE ПАКЕТІ",
      price: "120,606₸",
      userCount: "Қолданушы саны шексіз",
      description:
        "Үлкенірек бизнес, Банк қаржы, Сауда қызмет, Өндіріс кәсіпорындардың Тұтынушылар қызметі, Сатылым, Маркетинг командаларының өнімділігін арттыруға көмектесетін пакет.",
      button: "Ақпарат алу",
      link: "https://chatbot.mn/webform/dynamic/5537?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
    {
      id: 2,
      type: "VIP",
      price: "Шексіз пайдаланушылар",
      userCount: "Пайдаланушылар саны шектеусіз",
      description:
        "Өз іс қызметіне электронды көшуді жүзеге асыратын ірі компанияларға арналған арнайы пакет, мұнда кодтаудың әрбір жолын қалаған кодпен теңшеуге және дамытуға болады.",
      button: "Ақпарат алу",
      link: "https://chatbot.mn/webform/dynamic/5537?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
  ],
  priceTitle2: "Ұқсас ұйымдарды салыстыру",
  tableHd: [
    "БІЗДІҢ МҮМКІНДІКТЕР МЕН АРТЫҚШЫЛЫҚТАРЫМЫЗ",
    "Chatbot.mn",
    "Manychat",
    "Chatfuel",
  ],
  tableDt: [
    ["Бот дамытыу командасымен бот қурап дамыту", "+", "-", "-"],
    ["Тек сіздің мекемеңізге арналған нұсқау қызметі", "+", "-", "-"],
    ["Жауаптандырылған менеджер", "+", "-", "-"],
    ["24/7 сағат бойынша жауаптандырылған менеджер жұмыстау", "+", "-", "-"],
    ["Дүкен 2.0 меню", "+", "-", "-"],
    ["Жеткізү қызметі", "+", "-", "-"],
    ["Онлайн келісім-шарт", "+", "-", "-"],
    ["Дан систем", "+", "-", "-"],
    ["Масс мессеж", "+", "+", "+"],
    ["Ішкі төлем ақы мүмкіндіктері", "+", "-", "-"],
    ["Нұсқаулық, видео сабақтар", "+", "+", "+"],
    ["Бот дамытушылар группасы", "+", "+", "+"],
  ],
  priceCTA:
    "Сіз біздің сатылым менеджеріне байланысу арқылы филиал, мекеме артықшылығы, іс қызметтерңізге қарай қолайлы мәлімет алу арқылы өзіңізге керекді жүйеңзді таңдаңыз.",
  priceComment:
    "Іс қызметтеріне ЧАТБОТ жүйесін сәтті пайдаланып жатқан үздік мекемелер пікірлерінен",
  priceCommentList: [
    {
      id: 0,
      img: emch,
      description:
        "Тек сол қолданушыға арнап бергісі келген қалаған ақпараттарын блок немесе автомат жауаптармен қарым-қатынас сөйлем жүргізү арқылы жеткізүге болады.",
    },
    {
      id: 1,
      img: pn,
      description:
        "Қолданушылар күннің қай уақытында ақпарат сұрайды, егер біз жауап қайтара алмайтын болсақ сатылым істеү мүмкіндігі азайуы, қолданушылар қанағаттанбылаушығы болу, бизнес атақ абыройы құлдырау деген сияқды кемшілікдер болады. Бірақ біз бұл мәселені чатбот көмегімен шеше аламыз.",
    },
    {
      id: 2,
      img: itzone,
      description:
        "Чатбот артында бірге туратын командамыз мекеме, қолданушылардың артықшылығына қарай Технолоия және Цифрлық орта күннен-күнге дамып жатқан заманда оларға қажетті жақсартулар, дамытуларын өте тез істеп қызмет көрсетүі бізге артықшылықды болады.",
    },
  ],
  faqHeader: "ТАНЫМАЛ СҰРАҚДАР",
  faq: [
    {
      id: 0,
      title: "Ботты қалай жасауға болады?",
      description:
        "Егер сіз chatbot.mn сайтына бірінші рет кіріп жатсаңыз, тіркелуіңізді сұраймыз, ал егер оған қайта кіретін болсаңыз, «Кіру» түймесін басыңыз. Бір пайдаланушы 5 ботты тегін жасай алады. PRO пакетін 1 ай бойы пайдаланып көріңіз, содан кейін автоматты түрде ТЕГІН пакетке ауысыңыз.",
    },
    {
      id: 1,
      title: "Ботты қалай қосуға болады?",
      description:
        "Facebook page admin ұқығы бар адам чатботты қосуға және ажыратуға құқылы. Қосу түтікшесіе басып => EDIT SETTINGS-ге кіріп=>парақшаңызды таңдап=>барлық параметрлерін YES болдырып DONE басу.",
    },
    {
      id: 2,
      title: "Token expired болыпды қайту керек?",
      description: `FACEBOOK парақшасының админ құқығы, құпия сөз, АДМИН өзгертілген кезде token expired деген ҚАТЕЛІКТЕР КӨРСЕТЕДІ. Сондықдан сіз \n1. Дашбоардыңзға кіріп \n2. параметр менюге кіріп \n3. тазалау түймесін басып \n4. Facebook қосу түймесін басып \n5. Парақшаңызды таңдап қосыңыз.`,
    },
    {
      id: 3,
      title: "Созылуды қалай жасауға болады? Ол ұзартылмаса ше?",
      description:
        "Бақылау тақтасының жоғарғы оң жақ бұрышындағы пакетіне басқанда ботты ұзарту және боттың қосымша төлемді қызметтер таңдаулары шығады жәнеде таңдау жасаған соң есептеүлер алады немесе QR-арқылы төлемін төлеген сәтте бот ұзартылатын болады.",
    },
  ],
  productHeader: "ҚОСЫМЩА FEATURES",
  products: [
    {
      id: 0,
      type: "Менің дүкенім",
      description:
        "Қолданушы санат бойынша тауарларңызды іздеп оңай таба аласыз жәнеде өнімі туралы толық ақпараттары алуымен қатар таңдалған тауарын тура сатып ала алады",
    },
    {
      id: 1,
      type: "Коммент жауап",
      description:
        "Facebook парақшасындағы жазбаның астына пікір қалдыру арқылы ақпарат алғысы келетін тұтынушыларға 24/7 уақыт бойы пікірлерге автоматты түрде жауап беріп, толық ақпаратты уақыт жоғалтпай мессенджер арқылы жібереді.",
    },
    {
      id: 2,
      type: "Live Chat",
      description:
        "Тұтынушылардың мінез-құлқына, нақты жағдайларына, сұраныстарына және оларға сәйкес келетін қызметтерді алуға байланысты ақпарат алар кезде қызметкермен байланысу қажеттілігі тұтынушыларға шығад.",
    },
    {
      id: 3,
      type: "Жеткізү",
      description:
        "Тапсырыс беруші тауардың ақысын төлеп, жеткізуші компанияны таңдап, тапсырыс берген мекен-жайына жеткіздірү, бұл дүкен мәзірінің кешенді шешімі болып табылады.",
    },
    {
      id: 4,
      type: "Онлайн шарт",
      description:
        "Келісімшартқа қажетті ақпарат пайдаланушыдан сауалнама алу арқылы жиналады және автоматты түрде шарт жобасына жүктегенде келісімшарт дайын болып келісімшарт істеген екі тарапқа бір уақытта электронды пошта арқылы жіберіледі.",
    },
  ],
  mainFeature: {
    type: "Негізгі қызмет",
    description:
      "Чатбот ЖШҚ БОТ ҚУРАУ, ДАМЫТУ деген қызмет көрсетілүмен қатар мекеме жүргізетін іс шара артықшылығына қарай ІШКІ СИСТЕМАЛАРЫМЕН ҚОСЫЛЫП жұмыстайтын мүмкіндігі бар.",
  },
  subFeature: "Негізгі мүмкіндіктер",
  subDesc: [
    "Ақпарат ағымына қарай меню құру",
    "Танымал сұрақ жауап кіргізү",
    "Тауар өнімі туралы ақпараттар көрсетү",
    "Қолданушыны бір неше топтарға және сегмментерге бөлү",
    "Hubspot, erxes қосылу",
    "Тек сол ботты жауаптанған менеджер",
    "Аналитика көрү Мониторинг істеү",
    "Төлем ақы жинау",
    "Масс мессеж жіберү",
    "Сауалнама жүргізү",
    "Сурақды форм жүргізү",
    "Тапсырулы жолдама жіберү тағы басқа мүмкіндіктер",
  ],

  featureCTA:
    "Біз өз клиентермізбен, тұтынушылармызбен үнемі байланыста боламыз.",
  featureCTA2: [
    {
      title: "Белсендірү оқытуы",
      description:
        "Тұтынушылардың жұмыс артықшылығы, мүмкіндігіне қарай оқыту уақыт кестесін икемді болдырдық. Жұмыс күндерінің 10:00 және 14:00 сағаттан таңдауға болады. <br/>/оқыту 1:30 минут жалғастырылады/",
    },
    {
      title: "Тұтынушы қызметі",
      description:
        "Чатботға қатысты сұрақ, жақсартулар, қосымша ақпарат кіргізү, өзгертү істегенде біз нұсқаулық кеңесін берүге дайынбыз.",
    },
  ],
  productHeader2: "Артықшылықдар",
  productGrid: [
    {
      id: 0,
      img: grid1,
      title: "Белсендірү оқытуы",
      description:
        "Тек сол тұтынушыға арнап бергініз келген ақпараттарды блок және автомат жауаптармен қарым-қатынас жүргізү арқылы жеткізүге болады.",
    },
    {
      id: 1,
      img: grid2,
      title: "Маркетинг, Сатылым арнасы",
      description:
        "Чатботтар - ең тиімді коммуникациялық маркетинг құралдары арқылы тұтынушы тарту, әрі қарай сатұға айналдыруға болады.",
    },
    {
      id: 2,
      img: grid3,
      title: "Онлайн тапсырыс системасы",
      description:
        "Тұтынушылар өнім ақпараттарын бот арқылы алып, бірден жоғары түрдегі тауарды себеттеп сатып алумен қатар уақыт тапсыру, стөл тапсыру деген сияқды қызмет ала алады.",
    },
    {
      id: 3,
      img: grid4,
      title: "Жаңалықтар арнасы оқыту",
      description:
        "Қысқа және ұзақ уақыттық жеңілдік, жандандыру жаналықтарын масс смс арқылы чатботтың белсенді /активный/ тұтынушыдарына жеткізүге болады.",
    },
    {
      id: 4,
      img: grid5,
      title: "Тұтынушы сегменті",
      description:
        "Тұтынушыларды мінез-құлқы және қызықтаған өнімі, әрекеті бойынша бірнеше топ тарға бөліп, кейнгі маркетинг белсендірүлері, сатып алу, жеңілдіктер мен акцияларды оңтайлы бағыттауға болады.",
    },
    {
      id: 5,
      img: grid6,
      title: "Төлем шешімдері",
      description:
        "Ішкі және шет ел төлем системаларын қосылу істелген болғандықдан Сіз онлайн арқылы тауар өнімдернізді сатуңызмен қатар барлық тапсырыс күйін бір терезеден бақылауға болады.",
    },
    {
      id: 6,
      img: grid7,
      title: "Есептеме және деректер талдау",
      description:
        "Чатбот-арқылы қарым-қатынасқан барлық қолданушылардың қолданған саны уақытымен салыстырған график, кесте арқылы қарай алумен қатар бүгінгі және өткен уақыттың ақпараттарын кестеме арқылы талдауға болады.",
    },
    {
      id: 7,
      img: grid8,
      title: "Тұтынушы ұсыныс, шағым",
      description:
        "24/7  уақыт кестесімен тұтынушылар ұсыныс, шағымдарын қабылдап алып оларға қажетті ақпараттарын уақыт жоғалтпай жеткізіп, тұтынушының қызмет көрсететін командасын қолдап, өнімділік сапасын арттыра алады.",
    },
  ],
  button: {
    signin: "Кірү",
    signup: "Тіркелү",
    download: "Таныстыру жүктеү",
    free: "Тегін бастау",
    priceCTA: "Нусқаулық алу сұрау жіберү",
    dashboard: "Бақылау тақтасы",
    logout: "Шығу",
  },
  termTitle: "Қызмет көрсетү талабы",
  termList: [
    {
      header: "Негізгі талап",
      desc: [
        {
          d: "Chatbot.mn жасанды интеллектный тұтынушының автоматтандырлыған системасы “Чатбот” ЖШҚ- ның жауапты түрдегі қызметі болғандықдан осы көрсетілетін қызметердің шарттарын тұтынушы сол системаның қызметін қолданған сәтте пайда болатын екі жакдын ережелерін, қарым қатынасын турақтандырлымда болады.",
          d1: [],
        },
        {
          d: "Тұтынушы системаға тіркеліп акаунт ашқан уақыттан бастап акаунт жабылғанға дейін осы қызметтің шарттары жауапты түрде орындалады.",
          d1: [],
        },
      ],
    },
    {
      header: "Тұтынушы ескеретін шаралар",
      desc: [
        {
          d: "Chatbot.mn системасы Facebook Messenger, WeChat, Whatsapp, Telegram, Viber, Line осылармен дүние жүзлік ірі мессенджер платформаларымен байланысып жумыстай алады жәнеде осы кезде тек Facebook Messenger чатына қосылған болады.",
          d1: [],
        },
        {
          d: "Тұтынушы өзінің пайда болдырған ботының көмегіне қарай таратылып жатқан ақпараттар, сөйлемдер дегеніміз тек сол тұтынушы мен мессенджер тұтынушысының арасындағы ақпараттар болуымен қатар оларға “Чатбот” ЖШҚ қандайда бір заңды түрде жауапгершілік жүктемейді.",
          d1: [],
        },
        {
          d: "Chatbot.mn системасында пайда болған тұтынушы акаунты, боттың ақпараттары жәнеде месеннджер тұтынушысының сөйлемі дегендей барлық ақпараттарды “Чатбот” ЖШҚ бүкілдей жауаптанады жәнеде заңда көрсетілгеннен басқалай жағдайда үшінші әріптесге ашпауға жауапгершілік жүктейді.",
          d1: [],
        },
        {
          d: "Тұтынушы өзінің бір аккаунтына біреү немесе одан көп бот пайда болдыра алады жәнеде бір ботқа тек жалғыз facebook пейж қосыуға болады.",
          d1: [],
        },
        {
          d: "Тұтынушы өзінің пайда болдырған ботын тек өзінің басқаралатын дәрежедегі фейсбүүк пейжісіне қоса алады.",
          d1: [],
        },
        {
          d: "Системаға пайда болдырған бот арқылы тұтынушы мессенджер тұтынушысымен байланысу кезнде келескідей тыйым қойылған заңмен ісін жүргізүге болмайды. Оларға:",
          d1: [
            "Алдау сиақды әрекет, айыптаған, адамдардың атағына кір келтірген, қорлау, жәнеде зияткерлік меншікдің құқығын бузған ақпараттар кіргізү, тарату",
            "Кез келген ұйым және жеке адамның құпияасына қатысты ақпараттарды руқсатсыз жарияалау",
            "Мессенджер тұтынушысынан меншікді жәнеде сол адамның қаржы құпиясының ақпараттарына руқсатсыз талап қою.",
            "Монгол Мемілекетінің заңындағы тыйым қойылған әрбір әрекет істеү",
            "Сайлау мақсатында қолдануға тыйым қойылады.",
          ],
        },
        {
          d: "Chatbot.mn системасында тұтынушы пайда болдырған чат бот-ы қосылған мессенджер платформасымен ақпараттар ауысу арқылы жумыстаумен мен қатар сол мессенджердің платформасының қызмет шарттары жәнеде қупиялық ережелерін қатаң қадағалап жұмыстауға міндетті. Егерде тұтынушы сол мессенджер платформасының (Facebook) қызмет шарттарымен қосылымайтын іс жүргізілген кезде тұтынушы боттың қызметін тоқтатуға дейін әрекет қолданады.",
          d1: [],
        },
        {
          d: "Тұтынушы басқаларға chatbot.mn системасының кіріү қупия сөз, тұтынушы ақпараттарын басқаларға таратпау, соларға қатысты кездесіліүге болатын әрбір қауіптен сақтануға міндетті. Ол міндетін қадағаланбандықтан пайда болған зиянды “Чатбот” ЖШҚ і жауаптанбайтын болады.",
          d1: [],
        },
        {
          d: "Егер тұтынушы өзінің акаунтының қупияасын басқаға ұрдалған деп байқалса support@chatbot.mn немесе 70114858 номеріне жедел қабарласыңыз",
          d1: [],
        },
      ],
    },
    {
      header: "Төлемді қызмет алу, қайтару",
      desc: [
        {
          d: "Chatbot.mn системасын тегін керектенү мүмкіндіктерін тұтынушыға пайда болдырумен қатар ақылы қызыметтердің мүмкіндіктерін ұсыныс етеді.",
          d1: [],
        },
        {
          d: "Төлемді мүмкіндіктерді пайдалану үшін тұтынушы сол боттағы төлемді қызметті іске қосыу керек, кейін төлеү қызметіне контракт істеү керек.",
          d1: [],
        },
        {
          d: "Төлемді қызметтің тарифы chatbot.mn сайтында ашық түрде болауымен қатар сол айдың тұтынушы саны және төлемді мүмкіндіктің шыдамына қарай әрі түрлі бағада болады.",
          d1: [],
        },
        {
          d: "Тұтынушы өзінің пайда болдырған әр қайсы ботының  төлемді қызметтерін іске қоса алумен қатар қызмет ақысы әр қайсы бот сайын жеке есептеледі.",
          d1: [],
        },
        {
          d: "Сол айдың қызмет төлемінің талабы келескі айдың 1-інде автомат арқылы есептелеп тұтынушының тіркелінген мейліне жіберілетін болуымен қатар төлемді келескі айдын 20-сына дейін төлеү қажет. Егер келескі айдын 20-сына дейін төлемін төлемеген жағдайда тұтынушы боттың төлемді қызмет мүмкіндіктерін тұтына алмайтын болады.",
          d1: [],
        },
        {
          d: "Тұтынушы қалаған уақытында төлемді қызметті қайтаруға болады жәнеде сол төлемді қызметі қайтарылған уақытқа дейінгі төлем есептеме тұтынушыға жіберіледі.",
          d1: [],
        },
        {
          d: "Төлемді қызмет қайтарылған уақытта төлемді мүмкіндіктер белсенді емес болады және chatbot.mn системасын тегін мүмкіндіктерін әрі қарай жалғастырып пайдалана алады.",
          d1: [],
        },
        {
          d: "Ақылы пакеттің мерзімі біткен кезде тұтынушы автоматты түрде Тегін пакетке ауысады, ал ауысу кезінде чатбот қызметінің мәзірлері шектеледі. Сондай-ақ, пайдаланушы Chatbot қызметіне кіру кезінде жарнамалар мерзімді түрде жіберіледі.",
          d1: [],
        },
      ],
    },
    {
      header:
        "Мессенджер қосылымын ажырату, чат-боттарды жою және есептік жазбаларды жабу",
      desc: [
        {
          d: "Тұтынушы қалаған уақытында жасаған чатботының мессенджерден ажырата алады және сол сәтте мессенджер платформға (Facebook)  қатысты қарым-қатынас тарихы, мессенджер тұтынушысының ақпараты жойылатын болады. Facebook page парақшасының қосылымын ажыратқан соң басқа жаңа парақшаға қосуға мүмкіндігі бар.",
          d1: [],
        },
        {
          d: "Тұтынушы өзінің жасаған чатботын жоюға мүмкіндігі бар және осы кезде чатботға қатысты мессеж блок, қарым-қатынас, жасанды инттелект модел ақпарат дерегі жойылады.",
          d1: [],
        },
        {
          d: "Егер тұтынушы жасаған ботта төленбеген төлем есептемесі болатын болса ботты жоя алмайды.",
          d1: [],
        },
        {
          d: "Тұтынушы өзінің chatbot.mn системасының аккаунтын жабуға мүмкіндігі бар және сол сәтте чатботтар және чатботға қатысты барлық ақпарат жойылады",
          d1: [],
        },
        {
          d: "Тұтынушы акаунтында төленбеген есептеме болған жағдайда акаунтыны жаба алмайды.",
          d1: [],
        },
      ],
    },
    {
      header: "Басқа",
      desc: [
        {
          d: "Осы қызмет талабында “Чатбот” ЖШҚ-іс қызметнің жақсартуына қарай қосымша өзгеріс қосуға болады және қосымша өзгеріс кірген сәтте тұтынушыға алдын ала білдіреміз.",
          d1: [],
        },
        {
          d: "Chatbot.mn системасы “Чатбот” ЖШҚ атында Байланысты реттеу комиссиясының ресми түрдегі сайтында тіркелінген және chatbot.mn вэб парақшасы және жүйелік зияткерлік меншікді заңмен қорғалады.",
          d1: [],
        },
      ],
    },
  ],
  privacyTitle: "Қупияалық Саясат",
  privacyList: [
    {
      header: "Негізгі және Ақпараттар жинақтау",
      desc: [
        {
          d: "“chatbot.mn” ақылды чатбот жаратқыш платформ (Әрі қарай “Система” деген) келескідей жағдайда азамат,  заңды тулға /әрі қарай “Тұтынушы” деген/ қатар олардың Фейсбүк парақшасына тұтынушы /әрі қарай “Чатбот тұтынушы” деген/ ақпараттар жиналады. Оларға:",
          d1: [
            {
              d2: "Тұтынушы дегеніміз chatbot.mn веб системаға кіріп тіркелү пайда болдырып өзінің админ басқаратын құқығы бар Facebook парақшасын қосып чатбот тұтынушыларға автомат жауап қайтаратын чатботты уқсырып пайда болдырып қызмет көрсетіп жатқан жеке адам, заңды тұлғаны айтады.",
              d3: [],
            },
            {
              d2: "Чатбот тұтынушы дегеніміз Тұтынушының қосқан Facebook парақшасының Send Message батырмасы жәнеде басқалай Facebook Chat Plugin – арқылы автомат жауап қайтаратын чатботпен қосылған ең соңғы тұтынушыларды айтады.",
              d3: [],
            },
            {
              d2: "Тутынушы Системаға тіркелгенде электрондық почта, тегі, есімі, телефон нөмері және құпия сөзін кіргізеді система тұтынушы пайда болдырған құпия сөзін адам қатыссыз криптограф әдістемесі арқылы шифырланып дереқорға сақталатын болады оны ашып оқу мүмкін емес.",
              d3: [],
            },
            {
              d2: "Жәнеде қолданушы өзінің Фейсбүк парақшасын қосу үшін Фейсбүк- пен кіргенде система Қолданушының тегі, есімі, электрондық почта, туған кезі, жыныс, ашық түрдегі баптаулар сияқды ақпараттар мен қатар Facebook App ID, Access Token деген парақша қосуға қажетті ақпараттар қолданушының келісіммен  Facebook- ден алып сақтайды.",
              d3: [],
            },
            {
              d2: "Чат бот қолданушысы Тұтышының пайда болдырған автомат жауап қайтырғыш чатботпен қосылып қарым-қатынас жасай бастаған кезде системаға тіркеледі және тегі, есімі, электрондық почта,  телефон, туған кезі, жасы, жыныс дегендер келесілген жеке ақпараттар және Facebook PSID (Page Scoped ID) системаға сақтайды.",
              d3: [],
            },
            {
              d2: "Қолданушы Система төлемін және онлайн шопдың мүмкіндіктерін пайдаланған кезде мекеме сертификаты, азаматтық күәлігінің көшірмесі және шот ақпараттарын системаға кіргізіп сақтау қажет болады.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Ақпараттарды пайдалану шарттары",
      desc: [
        {
          d: "Қолданушы және Чатбот тұтынушысының системасына кіргізген барлық ақпараттарды жеке жеке өзінің мүлкісі болады да “Чатбот” ЖШҚ сы Тұтынушы жәнеде Чатботтың қолданушының рұқсатысыз төменде атап өтілген шарттардан басқалай түрде пайдаланылмайды.",
          d1: [
            {
              d2: "Қолданушы ретінде:",
              d3: [
                "Chatbot.mn cloud қызметінің төлемдері және тапсырыс ақпараттарын тексерү, оларға қатысты ақпараттар жіберү, қызмет төлемін төлеү (Қолданушы рұқсатымен)",
                "Қолданушыға қатысты тұтынушы қызметін көрсетү (Қолданушы рұхсатымен)",
                "Қолданушыға қатысты техникалық жөндеү қызмет көрсетү (Қолданушы рұхсатымен)",
                "Қолданушылар жаңа өнім және жеңілдіктер ақпараттарын жеткізү (Қолданушы рухсатымен)",
                "Чатбот ЖШҚ сы chatbot.mn қызметінің сапасын жақсарту, қосымша жаңа мүмкіндіктерді нарыққа еңгізү махсатымен қолданушының чатботы және чатботға қатысты ақпараттарды статистик болжамымен жеке адам, заңды тұлғаның құпиясыз ақпаратын алып пайдалануға болады.",
              ],
            },
            {
              d2: "Чатбот қолданушы ретінде:",
              d3: [
                "Система чатбот қолданушымен байланысу үшін сол чатбот қолданушысының Facebook PSID-ын пайдаланып мессеж жібереді.",
                "Қолданушы өзінің чатбот қолданушысының ақпаратын пайдаланып өзінің бизнесі жайлы ақпараттарын жіберү, тауар өнім және қызметі туралы ақпараттарын жіберү (Message Broadcasting немесе Facebook-ден рухсат етілген шектерде масс мессеж жібереді)",
                "Қолданушы Система арқылы өзінің чатбот қолданушыларына қолданушы қызметтері және басқалай қызмет көрсетілүге пайдаланылады",
              ],
            },
          ],
        },
      ],
    },
    {
      header: "Қолданушының ақпараттарын басқаларға тарату",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Тек Монгол мемлекетінің заңнамасы арқылы мемлекеттік органнан ақпарат беру туралы сұрау салынған жағдайда",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Қолданушы ақпаратын сақтау",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Chatbot.mn системасында пайда болу Қолданушы тіркелген ақпарат, чатбот ақпараттары, Чатбот қолданушысының ақпараты, әңгімелесу тарихы және басқадай барлық қолданушылардын және чатбот қолданушысының ақпараты құпиялылық пен қауіпсіздіктің жоғары деңгейінде  “Чатбот” ЖШҚ-ның Мобинет ЖШҚ деректер орталығындағы серверде сақталады.",
              d3: [],
            },
            {
              d2: "Қолданушы системаға кірү, құпия сөзін басқаларға жария етпеуге міндетті",
              d3: [],
            },
            {
              d2: "Кірү мәліметін басқаларға айырылған жағдайда туындаған Тұтынушы чатботына қатысты зияндарды “Чатбот” ЖШҚ-сы жауаптанбайтын болады. “Чатбот” ЖШҚ Тұтынушы кірү мәліметін пайдаланып кіре алмайтыннын ескертеміз.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Тұтынушы ақпаратын басқару",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Тұтынушы өзінің жеке және мекеме ақпаратын Система дашбоардысында қалаған уақытында өзгерте алады",
              d3: [],
            },
            {
              d2: "Егер Тұтынушы өзінің чатботын Фейсбук парақшасынан ажыратқан кезде автомат жауап қайтарғышы белсенді емес болады және қайта белсенді болдыруға дейін системада ақпараты сақталатын болады.",
              d3: [],
            },
            {
              d2: "Егер Тұтынушы өзінің чатботын жойған кезде чатбот және чатбот тұтынушысының ақпараттары системадан жойылумен қатар қайта қалпына келтірү мүмкін емес.",
              d3: [],
            },
            {
              d2: "Егер Тұтынушы өзінің тіркеү ақпаратын жойған болса қайта белсенді болғанша системада сақталады.",
              d3: [],
            },
            {
              d2: "Егер Тұтынушы өзінің тіркеү ақпаратын жойғысы келсе info@chatbot.mn  электрондық почтаға сұрауын жіберген соң біз жұмыс күндерінің 3-5 күн ішінде “chatbot.mn” системадан тіркеү ақпаратын жойып қабарлайтын боламыз.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Құпиялық саясат жаңартуы",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "“Чатбот” ЖШҚ  Құпиялық саясатына өзгеріс кіргізетін құқықты. Құпиялық саясатға өзгеріс кіргізілген жағдайда chatbot.mn веб сайтына ашық түрде қойылады Тұтынушы танысұға жауапды.",
              d3: [],
            },
          ],
        },
      ],
    },
  ],
  heroStat: {
    col1: "Чатбот қолданған тұтынушылар",
    col2: "Брендтерге арналған виртуалды көмекшілер бот дамытылған",
    col3: "Бизнес ұйымдар, тұтынушылармен",
  },
  productHeader3: "Топтардың үйлестіру бөлімі",
  productSlide: [
    {
      id: 0,
      type: "МАРКЕТИНГ ТОБЫ",
      title:
        "Тиімді маркетинг, адал клиенттер және тұрақты қарым-қатынастар жасайды.",
      description: [
        "Компания мен тұтынушы арасында қарым-қатынас жасаңыз.",
        "Тұтынушы тәжірибесін жақсартыңыз",
        "Позитивті бренд түрін жасаңыз",
        "Бренд туралы жеткілікті білімі бар және брендті жақсы көретін тұтынушыларды дайындаңыз",
      ],
    },
    {
      id: 1,
      type: "САТЫЛЫМ ТОБЫ",
      title:
        "Технологиялық жетістіктермен бизнес үлгілерін жаңартыңыз және оларды сату үшін пайдаланыңыз.",
      description: [
        "Сату арнаңызды кеңейтіңіз",
        "Мүмкіндікті тұтынушылар туралы ақпаратты жинаңыз",
        "Сатылым тобының қызметіне қолдау көрсет",
        "Сатылым процесін автоматтандыр",
      ],
    },
    {
      id: 2,
      type: "ҚЫЗМЕТ КӨРСЕТУ ТОБЫ",
      title:
        "Қызметтен алынған қанағаттанушылық қайталап сатып алуға ынталандырады",
      description: [
        "Тұтынушыларға тәулік бойы қажетті ақпаратты жеткізіңіз",
        "Ұсыныстар мен шағымдарды жүйелі түрде жедел шешіңіз",
        "Тұтынушыларға қызмет көрсету тобының өнімділігін арттырыңыз",
        "Адал тұтынушыларды құру арқылы қайталанатын сатып алуларды көбейтіңіз",
      ],
    },
  ],
  footer: {
    title: "CHATBOT-ДЫ ТЕГІН СЫНАП КӨР",
    subtitle: "ОҢАЙ ӘРІ АНА ТІЛІҢДЕ ЧАТБОТЫҢЗДЫ БАСТАҢЫЗ",
    col1: "Чатбот",
    row1: "Біз туралы",
    row2: "Танымал сұрақтар мен жауаптар",
    row3: "Чатбот пайдаланушыларының тобы",
    row4: "Хабарласу",
    col2: "ӨНІМ",
    row5: "Ботты дамыту",
    row6: "Мүмкіндіктерді арттыру тренингі",
    row7: "Қызмет пакеттері",
    row8: "Клиенттерге қызмет көрсету",
    col3: "КӨМЕК",
    row9: "Нұсқаулық",
    row10: "Онлайн кеңес",
    row11: "Нұсқаулық",
    row12: "Темплейт",
    copyright: "Барлық құқықтар заңды жүзінде сақталған",
    year: "",
  },
  about: {
    title: "БІЗ ТУРАЛЫ",
    p1: "Технология саласында көп жылдық тәжірибесі бар Моңғол жастары негізін қалаған NSP LLC 2019 жылдан бастап Chatbot платформасын әзірлеп, Start Up-тан бастап тәуелсіз Chatbot LLC компаниясы құрылды.",
    p2: "Мемлекеттік және жеке бизнес секторларындағы 3500-ден астам ұйымның күнделікті тұтынушыларға қызмет көрсетуін автоматтандырады және бизнеске жаңа технология мүмкіндіктерін енгізуді жалғастыруда.",
    row1Title: "ЕҢ ЗОР МАҚСАТ",
    row2Title: "ҚҰНДЫЛЫҚТАР",
    row3Title: "АРАЛЫҚТАН КӨРІНУ",
    row1: "Моңғол жастарының интеллектуалдық күші мен бірлігін әлемдік аренаға шығару",
    row2: ["Топтық жұмыс", "Көзқарас", "Табандылық"],
    row3: "Біз Оңтүстік-Шығыс Азия және жаһандық нарықтарда бәсекеге түсетін қабілетті өнім болдырып Chatbot платформасын одан әрі дамытып, қызметтерімізді көптеген арналар арқылы енгізуді көздеп отырмыз.",
  },
  input: {
    email: "Электрондық пошта",
    pwd: "Құпия сөз",
    lName: "Тегі",
    fName: "Аты",
    phone: "Телефон",
    forgot: "Құпия сөзіңізді ұмыттыңыз ба?",
    remember: "Мені ұмытпаңыз",
    signinif: "Тіркелдіңіз бе? кірү",
    signupif: "ТІРКЕЛМЕГЕН БОЛСАҢЫЗ", //  ТІРКЕЛУ ҮШІН ОСЫ ЖЕРГЕ БАСЫҢЫЗ  //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    signupifLink: "ТІРКЕЛУ ҮШІН ОСЫ ЖЕРГЕ БАСЫҢЫЗ.", //  ТІРКЕЛУ ҮШІН ОСЫ ЖЕРГЕ БАСЫҢЫЗ  //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    acceptTerm: '"ТІРКЕЛУ" БАСУ АРҚЫЛЫ СІЗ ТІРКЕҮ ЖАСАЙСЫЗ ЖӘНЕ БІЗДІҢ',
    acceptTerm2: "КЕЛІСЕСІЗ.",
    term: "ҚЫЗМЕТ КӨРСЕТУ ШАРТТАРЫМЕН ЖӘНЕ",
    privacy: "ҚҰПИЯЛЫҚ САЯСАТЫМЫЗМЕН ",
    forgotTitle: "ТІРКЕЛГЕН ЭЛЕКТРОНДЫҚ АДРЕСТІ ЕНГІЗІҢІЗ",
    forgotButton: "Құпия сөзді қалпына келтіру",
    button: "Facebook арқылы тiркелү",
    label1: "Электрондық поштаны енгізіңіз",
    label2: "құпия сөзді енгізіңіз",
    label3: "фамилияны енгізіңіз",
    label4: "атын енгізіңіз",
    label5: "телефон нөмірін енгізіңіз",
  },
  tutorial: {
    h2: "Байденмен қатысу",
    h3: "Оқу тақырыптары",
    h4: "Чатбот мүмкіндіктері №",
    button: "Толығырақ",
    input: "Іздеу...",
    templateTitle: "Қатысушы Тасымалдар",
    request: "Біздің бағдарламамызға жазылғыңыз келеді ме?",
    formName: "Сіздің атыңыз",
    formOrg: "Ұйым",
    formDate: "Күн / Осы күні 10:00-де жазылуға болады /",
    formEmail: "Сіздің электрондық пошта мекенжайыңыз",
    formPhone: "Сіздің байланыс телефоныңыз",
    formSubmit: "Сұраным жіберу",
    phoneLabel: "Телефон нөмірі: ",
    phone: "77441616",
    addressLabel: "Біздің мекенжайымыз: ",
    address:
      "Моңғолия, Улан-Батор, Сүхбаатар ауданы, Энхтайвны өргөн чөлөө, 15A/5, Ambassador office, 1-ші қабат 104",
    paragraph:
      "Сіз маркетингтік клиенттермен байланысты бот қызметінде пайдалану және тәжірибелік бот дайындаушылардан маңызды ақпаратты және мүмкіндіктерді сәтті қалай пайдалануға арналған егжей-тегжейлі ақпарат аласыз.",
    cards: [
      {
        id: 1,
        title: "Мәлімет жүктеу",
        url: "https://www.youtube.com/embed/GBHuScg_5Mc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Сіз өз әзірлегеніңіздің барлық түрлі ақпараттарын, суреттер, мәтіндер, бейнелер, сілтемелерді және басқаларын 7 күн ішінде пайдаланушыларға жіберіп, олардың талаптарына сай жеткізу мүмкіндігі бар.",
      },
      {
        id: 2,
        title: "Шағын тілде көрісу",
        url: "https://www.youtube.com/embed/YhdgfHWzPAU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пайдаланушылардан келетін сұрақтарға жауап беріп, оларға ақпараттарды қамтымай жіберу мүмкіндігі бар.",
      },
      {
        id: 3,
        title: "Шағын сұрақ-жауап",
        url: "https://www.youtube.com/embed/YxUOWzF2FuI?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пайдаланушылардың жүргізу кезінен бастап шағын сұрақтар және олардың жауаптарын автоматты түрде жасау және ақпараттарды жылдам жіберу мүмкіндігі бар.",
      },
      {
        id: 4,
        title: "Онлайн агент",
        url: "https://www.youtube.com/embed/qAoZvdaLWJw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пайдаланушылар чатбот арқылы онлайн агенттімен қарым-қатынас айналыса алатын және олардың қажеттіліктеріне сай жетісті ақпараттарды бере алатын мүмкіндігі бар. Содан кейін, бизнестер пайдаланушылардың бағаларына негізделіп, жауаптарды бақылау арқылы қызмет көлемін жақсарту мүмкіндігі бар.",
      },
      {
        id: 5,
        title: "Сауалнама формасы",
        url: "https://www.youtube.com/embed/OAgFNUG1NTg?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот арқылы сауалнама формасын жасау және пайдаланушылардан пікір беріп, різді сараптамаларды және ақпараттарды чат форматында жинау мүмкіндігі бар.",
      },
      {
        id: 6,
        title: "Веб-форма",
        url: "https://www.youtube.com/embed/Hth3BvrSgyo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чаттың ішінде қосымша терезе құрастыру және пайдаланушылардан деректерді жинау мақсатында топтыру мүмкіндігі бар. Веб-форманы толтырып кеткен соң, жауаптарды электрондық поштамен алу немесе Excel файлы түрінде жүктеп алу мүмкіндігі бар.",
      },
      {
        id: 7,
        title: "Топтық хабарлама",
        url: "https://www.youtube.com/embed/YTgT3gQAFIA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатботпен әр түрлі пайдаланушыларға стандартты және детальды ақпараттарды жіберіп жатқанда және 24 сағат ішінде немесе белгіленген уақыт аралығында ақпараттарды топтау мүмкіндігі бар.",
      },
      {
        id: 8,
        title: "Комментарийг жауап беру",
        url: "https://www.youtube.com/embed/yaNh9Nteaqk?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Facebook беттегі постпенің ақпараты алдында пайдаланушылар коммент жазғанда, чатбот автоматты түрде коммент жауап беріп, комментердің сәйкес келетін сөздері бойынша орнатылған жауаптар жіберуге болады және түрлі мазмұн бар комментпен басқа да коммент жауаптауға болатын болады. Сондай-ақ, чатботта сақталған арнайы сөздермен алакан болмайтын пайдаланушылардан комменттерді жоюға болады.",
      },
      {
        id: 9,
        title: "Пайдаланушылар деректері",
        url: "https://www.youtube.com/embed/eENdtu7CSis?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пайдаланушылар, клиенттер, чат тарихы, сарапшықтар және веб формаларымен байланысты мәліметтер чатботтың баспанасында сақталуы мүмкін.",
      },
      {
        id: 10,
        title: "Пайдаланушы тегі",
        url: "https://www.youtube.com/embed/e1dg50_w9GE?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот пайдаланушыларын олардың мәртебесі, әрекеттері бойынша тегтерге бөліп, оларды оңай басқаруға мүмкіндік береді. Сондай-ақ, арнайы топтар жасау және оларға арналған ақпаратты басқаруға мүмкіндік береді.",
      },
      {
        id: 11,
        title: "Статик беттер",
        url: "https://www.youtube.com/embed/57HuwrdPk4o?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чат мессенжері ішінде статик ақпараттық терезелер жасау мүмкін.",
      },
      {
        id: 12,
        title: "Жүйе интеграциясы",
        url: "https://www.youtube.com/embed/FXZgnKigRkQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Google Sheets, Google Calendar, Hubspot гиби жекелештік жүйелермен интеграция жасау бизнес операциялары үшін мүмкін.",
      },
      {
        id: 13,
        title: "Аналитика",
        url: "https://www.youtube.com/embed/Ez1eO5ce6E4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пайдаланушылардың сатып алу және әрекеттері туралы статистикалық деректерді көру, күнделікті, айдың күндері бойынша шығару және оның бойынша Excel файлына жүктеу мүмкін.",
      },
      {
        id: 14,
        title: "Мониторлеу",
        url: "https://www.youtube.com/embed/qfWTKhNH660?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Монитор бөлімі боттың қателері, пайдаланушылардан жауап берілмеген сұраулар және пайдаланушылардың жасаған әрекеттері күндік, айдық және күндер бойынша көрсетеді.",
      },
      {
        id: 15,
        title: "Дүкен 2.0",
        url: "https://www.youtube.com/embed/-7VLTCTJeg0?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Дүкен 2.0 - бұл мұнда пайдаланушыларға чат пенінен шығу жоқ болуымен тауарларды көрсетіп алуға болатын онлайн дүкеніңіз. Ол внутреннікі ақша төлем жүйелерімен QPay және SocialPay жігеруімен интеграцияланған, олардың көмекшілігімен қолжетімді және ыңғайлы төлем жүктелулері. Дүкен 2.0-пен сіз: \nТауарларды дүкенге қосу. \nЖеңілдіктер орнату. \nПайдаланушы-айналым бойынша ақпарат қосу. \nТауарларды санаттау. \nАрнайы ұсыныстар жасау. \nБаннерлер көрсету. \nКоллекциялар жасау. \nТаймерлерді басып тастау. \nАқпараттарды және тауарлардың орналасуын басқару. \nПайдаланушылардың таңдалған тауарларын себетке қосу. \nЖеткізу мекенжайларын теңшеу. \nТауарларды сатып алу үшін онлайн төлем жасау мүмкіндігін қосу.",
      },
      {
        id: 16,
        title: "Төлем Интеграциясы",
        url: "https://www.youtube.com/embed/FpRjP7BcEJU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот тек ұйымдармен және пайдаланушылар арасында байланыс жасайтын автоматтандырушы емес. Ол сондай-ақ, жекелештік төлем жүйелерімен интеграция жасап, көптеген тауарлар және қызметтер үшін төлем есептерін жүргізу үшін қабілетті.",
      },
      {
        id: 17,
        title: "Жеткізу",
        url: "https://www.youtube.com/embed/ucvgziyd5uM?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот дүкені 2.0 бөлімін ефективті түрде басқаратын, жеткізу компанияларының жүйелерімен интеграцияланып, автоматты және пайдалы тауар жеткізулерін орналастырады.",
      },
      {
        id: 18,
        title: "Тапсырыс",
        url: "https://www.youtube.com/embed/JInmNgDLLwA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Тапсырыс бөлімі пайдаланушыларға QPAY және Social Pay арқылы тапсырыстар беріп, төлем жасауға мүмкіндік береді. Ол толық тапсырыс беру, тауар және қызмет сатып алу, билет брондау және тіркеуді растау үшін төлем есептерін орналастырады.",
      },
      {
        id: 19,
        title: "Бот жасау, беттерге байланыс",
        url: "https://www.youtube.com/embed/rGJCF4wNNR4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Бұл нұсқаулықта, жаңа ботты қалпынан жасау, жасалған боттардың барлық тізімін көру, аты бойынша іздеу және сіздің операцияларға ыңғайлы бот модельдерін кірістіруге, боттардыңызды чатбот жүйесіндегі тіркелген пайдаланушылармен байланысуға, рұқсаттар беру және мәліметтерімен байланысуға болады.",
      },
      {
        id: 20,
        title: "Хабарлама блок",
        url: "https://www.youtube.com/embed/kR57nSi3lOw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Хабарлама блок бөлімі пайдаланушыларға мәліметтерді тапсыру үшін таза жұмыс жасауға мүмкіндік береді, оларды тігізу арқылы әрекеттердің жиілігін тексеруге болады және боттың жақсы жасалуы үшін бас бет есімін көрсетеді.",
      },
      {
        id: 21,
        title: "Баптаулар: Үйімділік",
        url: "https://www.youtube.com/embed/r1Dq405xpKo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Баптаулар: Үйімділік беті 5 бөлімнен тұрады: танымал, бастапқы хабарлама, рұқсат берілген домендер, персонаж, боттың баптаулары. Бұлар пайдаланушыларға бірінші әрекетін орнатуға, беттеріндегі тіркелген домендерді анықтауға және боттың аватарын немесе мәзір суретін таңдауға болады.",
      },
      {
        id: 22,
        title: "Баптаулар: Құттықтау",
        url: "https://www.youtube.com/embed/xIn51RFCXXc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Баптаулар: Құттықтау бөлімінде байрамга арналған құттықтауларды, күнделікті құттықтауларды, белсенділіктік әрекеттерге арналған құттықтауларды және бетшелеген хабарламаларды орнатуға болады.",
      },
      {
        id: 23,
        title: "Баптаулар: Пайдаланушыдан растау",
        url: "https://www.youtube.com/embed/FQqOZJ8l_XY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Баптаулар: Пайдаланушыдан растау бөлімі алғашқы рет организацияныздың чатботына кірушілерге қажетті ақпарат және сұрауларды беруге мүмкіндік береді, олардың телефон нөмірін жазу және электрондық пошта мекенжайын тіркеуге рұқсат беру арқылы бизнес орталығы үшін деректерді жинауға болады.",
      },
      {
        id: 24,
        title: "Баптаулар: Басты бөлім",
        url: "https://www.youtube.com/embed/0nXYYo1kpig?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Басты бөлім пайдаланушыларға мессенжер терезесінің оң жақ көлемінде көрсетілетін мәліметтерді орналастыруға мүмкіндік береді, осы бөлімнің мәліметтерімен жақсы нәтижелер көрсету.",
      },
      {
        id: 25,
        title: "Пайдаланушылар",
        url: "https://www.youtube.com/embed/xTIwo1l3A-g?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пайдаланушылар бөлімі арқылы сіз организацияныздың чатботына тіркелген барлық пайдаланушылар тізімін көре аласыз және әрбір пайдаланушының чат тарихы жайлы толық мәліметтерді көре аласыз және оларға хабарлама жібере аласыз.",
      },
      {
        id: 26,
        title: "Пайдаланушы атрибуттары",
        url: "https://www.youtube.com/embed/vefrnuDnGyY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Атрибуттар пайдаланушыларға берілген характеристиктерге сілтемелерді айырбастайды және орталықтар оларды бойынша сегменттеуге болады, мұндағы деректерді пайдалана отырып, олар арқылы массалық хабарламалар жіберу сияқты маркетинг әрекеттерін жүргізуге болады.",
      },
      {
        id: 27,
        title: "Пайдаланушы сегменттері",
        url: "https://www.youtube.com/embed/vWOzDqq3cPQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Тегтер және атрибуттарды қолдана отырып тіркелген пайдаланушыларды сегменттеу және оларға массалық хабарламалар жіберу.",
      },
      {
        id: 28,
        title: "Тапсырыс растау",
        url: "",
        description:
          "Тапсырыс растау хабарламалары кез келген жолмен алынған әрекеттер туралы хабарлайды, сатып алуда сәтті болу, немесе тауар және қызмет жеткізу нысанын растау үшін жіберіледі.",
      },
      {
        id: 29,
        title: "JSON API",
        url: "",
        description:
          "Пайдаланушылар деректерін алу үшін API арқылы қосылып, өрістердің ақпараты, уақыт жоспары, тауар ақпараты, белгілі күн ауа-райы, таңдалған валюта ауыту деректері пайдаланылады.",
      },

      {
        id: 30,
        title: "Мазмұн",
        url: "",
        description:
          "Мазмұн бөлімі різді жедел командалар арқылы қатынасушыларға қолайлы мазмұнды ұқсасуға мүмкіндік береді, оларға ұсынылатын мазмұнды жығыңдату арқылы пайдаланушыларға жығыңдатуға болады.",
      },
    ],
    templates: [
      {
        id: 1,
        title: "Онлайн сату",
        img: ecommerce,
        button: "Үлгіні көру",
        desc: "Электрондық платформа арқылы барлық өнімдер мен қызметтерді онлайн түрде сатуға мүмкіндік береді",
        list: [
          "Өнімдер санатын көрсету",
          "Өнімдер түрін көрсету",
          "Өнімдер туралы ақпаратты беру",
          "Өнімдердің жеңілдік пен тауарлардың кепілдігі",
          "Тауарлардың заңды және сатуына және сатып алудың барлық нүктелерін басқару",
          "Тапсырыс орналау, жеткізу және төлемді өңдеу",
          "Акциялар мен жеңілдіктер орнату",
          "Складдағы заттардың санын көрсету",
        ],
      },
      {
        id: 2,
        title: "Денсаулық қауіпсіздігі",
        img: hospital,
        button: "Үлгіні көру",
        desc: "Пайдаланушылардың денсаулығына деген кепілдік беретін әлеуметтік бот",
        list: [
          "Денсаулық бойынша кеңістік мәліметтер беру",
          "Денсаулық мамандарынан кеңістік ақпарат алу",
          "Пайдаланушылардың сұрауларына жауап беру",
          "Конкретті тауарлар мен қызметтердің ұсынысы",
          "Уақытша және табысы бар қызметтерді жүзеге асыру",
          "Сауалнамалар мен денсаулық талқылауларды жүзеге асыру",
          "Жұмыс кестесін көрсету",
          "Мекенжай ақпаратын беру",
        ],
      },
      {
        id: 3,
        title: "Ресторандар мен Зоопарктар",
        img: restaurant,
        button: "Үлгіні көру",
        desc: "Ресторандар, зоопарктар, кафелер, барлар, паблар, караоке және т.б. үшін",
        list: [
          "Менюды көрсету",
          "Пікір және ұсыныс табу",
          "Тамақ тапсыру",
          "Ширеу тапсыру",
          "Жұмыс уақытын көрсету",
          "Мекенжай ақпараты",
          "Акцияларды көрсету",
          "Тапсырыстарға жауап беру",
        ],
      },
      {
        id: 4,
        title: "Қорын-келін қазынасы",
        img: realestate,
        button: "Үлгіні көру",
        desc: "Қорын-келін мүліктердің кепілдіктерін организациялау",
        list: [
          "Агенттермен кездесу уақытын алу",
          "Қорын-келін мүліктері туралы ақпарат және ұсыныс беру",
          "Қорын-келін мүліктерін сату жазылулары",
          "Қорын-келін мүліктерін жалғастыру және сатып алу",
          "Қорын-келін мүліктерін жазылу",
          "Рекламалық жазбаларды белгілеу",
          "Жазбаларды санаттау және сүзу",
          "Мүшелер туралы ақпаратты тіркеу",
        ],
      },
      {
        id: 5,
        title: "Білім",
        img: education,
        button: "Темплейт көру",
        desc: "Оүтнуларға ақпарат тілеу және тіркеу арқылы автоматты түрде білім беру",
        list: [
          "Тілек тапсырыстарға жауап беру",
          "Қажетті ақпаратты тілеу",
          "Оүтнулардың тіркелуін автоматты түрде жүргізу",
          "Оүтнулардың сабақты таңдау және графиктерді жіберу",
          "Мұғалімдер мен бағыттардың ақпаратты тілеу",
          "Жаңа оүтнушыларға ақпарат тілеу",
          "Ұсыныстарды жіберу",
          "Сұраныстарды беру",
        ],
      },
    ],
  },
};
