import Hero from "./Home/Hero";
import SecondDiv from "./Home/SecondDiv";
import VerticalDiv from "./Home/VerticalDiv";
import Subscribe from "./Home/Subscribe";

function Home() {
  return (
    <>
      <Hero />
      <SecondDiv />
      <VerticalDiv />
      <Subscribe />
    </>
  );
}

export default Home;
