import spay from "../../assets/spay.png";
import qpay from "../../assets/qpay.png";
import google from "../../assets/google.png";
import hub from "../../assets/hub.png";
import integrations from "../../assets/integrations.png";
import hand from "../../assets/hand.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function Subscribe() {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setValue("");
  };

  return (
    <>
      {/* <div className="flex flex-col-reverse items-center md:flex-row md:space-x-6 justify-center md:p-10 lg:p-20">
          <button className="bg-customPink button border-customPink text-white font-bold scale-75 md:scale-100 text-[14px] lg:text-[16px] w-[340px]">
            Бүртгүүлэх
          </button>
          <button className="bg-customPink button border-customPink text-white font-bold scale-75 md:scale-100 text-[14px] lg:text-[16px] w-[340px]">
            Чатбот хэрэглэгчдийн групп
          </button>
        </div> */}
      <div className="lg:container">
        <div className="lg:flex lg:justify-center lg:h-[480px] 2xl:h-[480px]">
          <div className="lg:flex lg:justify-center lg:items-center">
            <div className="lg:flex lg:flex-col lg:w-1/2">
              <p className="text-center md:py-6 flex-1 text-[16px] lg:text-[1.1vw] xl:text-[20px] px-6 xl:px-16 lg:text-left font-black">
                {t("integration")}
              </p>
              <p className="text-black text-[14px] normal-case px-6 pb-2 text-center xl:px-16 lg:text-left lg:text-[16px]">
                {t("integrationDesc")}
              </p>
            </div>
            <div className="relative hidden lg:block">
              <img src={integrations} className="w-[460px] h-[310px]" alt="" />
              <img
                src={hand}
                className="w-[150px] h-[120px] absolute right-[41%] bottom-[-25%] hidden lg:block"
                alt=""
              />
            </div>
            <div className="flex items-center justify-evenly xs:hidden lg:hidden py-5">
              <img src={spay} className="h-[50px]" alt="" />
              <img src={qpay} className="h-[30px]" alt="" />
              <img src={hub} className="h-[30px]" alt="" />
              <img src={google} className="h-[50px]" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#08315f]">
        <div className="lg:flex lg:container text-white lg:px-16 2xl:px-48 lg:py-12">
          <p className="text-center p-6 md:px-6 flex-1 text-[14px] font-bold md:hidden">
            {t("subscribeM")}
          </p>
          <p className="text-center lg:text-left text-[#99FFF9] normal-case p-6 lg:px-16 flex-1 text-[14px] font-medium hidden md:block">
            {t("subscribeW")}
          </p>
          <form
            className="flex flex-col md:flex-row items-center justify-center lg:justify-end pb-4 lg:flex-1 px-4"
            onSubmit={handleSubmit}
          >
            <input
              className="w-[80%] rounded-[50px] md:w-[50%] lg:w-[55%] h-10 p-4 text-black focus:outline-none"
              placeholder= {t("input.email")}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              className=" my-4 bg-[#01a5f9] md:ml-4 font-black"
              type="submit"
            >
              {t("button.signup")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Subscribe;
