import vert1 from "../vert-1.png";
import vert2 from "../vert-2.png";
import vert3 from "../vert-3.png";
import vert4 from "../vert-4.png";
import grid1 from "../grid-1.png";
import grid2 from "../grid-2.png";
import grid3 from "../grid-3.png";
import grid4 from "../grid-4.png";
import grid5 from "../grid-5.png";
import grid6 from "../grid-6.png";
import grid7 from "../grid-7.png";
import grid8 from "../grid-8.png";
import emch from "../emch.png";
import euro from "../euro.jpeg";
import pn from "../pn.png";
import itzone from "../itzone.png";
import ecommerce from "../ecommerce.png";
import education from "../education.png";
import hospital from "../hospital.png";
import realestate from "../realestate.png";
import restaurant from "../restaurant.png";

export const en = {
  nav: {
    li1: "Service",
    li2: "Price",
    li3: "Opportunity",
  },
  navFeatures: [
    {
      id: 0,
      type: "Blog",
      route: "https://blog.chatbot.mn/",
    },
    {
      id: 1,
      type: "Manual",
      route: "https://help.chatbot.mn/",
    },
    {
      id: 2,
      type: "Support",
      route: "https://www.messenger.com/t/308105179819485",
    },

    // {
    //   id: 2,
    //   type: "Guide",
    //   route: "/",
    // },
    {
      id: 3,
      type: "Online course",
      route: "/tutorials",
    },
    // {
    //   id: 4,
    //   type: "Video course",
    //   route: "/",
    // },
  ],
  hero: {
    h2: "Your Business Equipment",
    h1: "TRANSFORM YOUR PROSPECTS INTO CUSTOMERS!",
    h3: "Virtual assistant for everyone",
    companies:
      "Industry Leaders Successfully Implementing Chatbot Services in Their Operations",
    bubble1: "Hello. It's Chatbot.mn auto responder.",
    bubble2: "What is a chatbot?",
    downloadLink:
      "https://chatbot.mn/uploads/home/Taniltsuulga%20English%20NEW%205.pdf",
  },
  vertTitle: "Chatbots are essential marketing tools",
  vertData: [
    {
      id: 0,
      title: "STRENGTHEN YOUR MARKETING CHANNELS",
      description:
        "By using chatbots in your marketing activities, you can attract new prospects, nurture them, and ultimately turn them into loyal customers of your company and brand.",
      img: vert1,
    },
    {
      id: 1,
      title: "IMPROVE AND ADVANCE YOUR OPERATIONS",
      description:
        "It is possible to collect user data through the chatbot, analyze the collected data as desired, and further improve and develop the business with its help.",
      img: vert4,
    },
    {
      id: 2,
      title: "OPTIMIZE YOUR CUSTOMER SERVICE",
      description:
        "Receive customer complaints and suggestions through chatbots and provide them with the information they need 24/7.",
      img: vert3,
    },
    {
      id: 3,
      title: "INCREASE SALES REVENUE",
      description:
        "By placing your products in the STORE feature, you can access all the necessary features, including checkout, inventory management, stock track, and delivery options for successful online sales.",
      img: vert2,
    },
  ],
  integration: "CONNECT WITH THE TOOLS YOU REGULARLY USE IN YOUR OPERATIONS.",
  integrationDesc:
    "By integrating our apps with your tech tools, you can boost team productivity, improve customer satisfaction, and achieve more with the assistance of a chatbot.",
  subscribeM:
    "Sign up below with your email to keep getting interesting news and updates.",
  subscribeW:
    "Stay updated on exciting news about conversation marketing and tech trends, chatbot development opportunities, new products, and more. Just enter your email below.",
  priceTitle:
    "Choose from the following plans based on your company's goals and activities.",
  price: [
    {
      id: 0,
      type: "PRO PLAN",
      price: "300,000₮",
      userCount: "User count",
      description:
        "A plan for freelancers, small, and medium-sized businesses to enhance customer communication, manage relationships, and foster growth.",
      button: "Sign Up",
      link: "",
    },
    {
      id: 1,
      type: "ENTERPRISE PLAN",
      price: "900,000₮",
      userCount: "Unlimited User count",
      description:
        "A plan designed to boost the productivity of large businesses in the banking, trading, and manufacturing sectors, as well as their customer service, sales, and marketing teams.",
      button: "Get Information",
      link: "https://chatbot.mn/webform/dynamic/5536?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
    {
      id: 2,
      type: "VIP",
      price: "Unlimited users",
      userCount: "Unlimited user count",
      description:
        "A customized package for large companies undergoing digital transformation, allowing you to tailor and develop code as needed for every aspect of your operations.",
      button: "Get Information",
      link: "https://chatbot.mn/webform/dynamic/5536?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
  ],
  priceTitle2: "Comparison with similar companies",
  tableHd: [
    "OUR CAPABILITIES AND ADVANTAGES",
    "Chatbot.mn",
    "Manychat",
    "Chatfuel",
  ],
  tableDt: [
    ["Creating bots by bot developers.", "+", "-", "-"],
    ["Consulting service tailored to your company", "+", "-", "-"],
    ["Dedicated manager", "+", "-", "-"],
    ["Customer support 24/7", "+", "-", "-"],
    ["Shop 2.0 feature", "+", "-", "-"],
    ["Delivery service", "+", "-", "-"],
    ["Online contract", "+", "-", "-"],
    ["DAN system", "+", "-", "-"],
    ["Mass message", "+", "+", "+"],
    ["Local payment opportunities", "+", "-", "-"],
    ["Manuals and video lessons", "+", "+", "+"],
    ["Bot developer group", "+", "+", "+"],
  ],
  priceCTA:
    "Contact our sales manager for personalized guidance on selecting the right plan for your industry, company, and needs.",
  priceComment:
    "Industry leaders have shared their experiences and success stories of implementing chatbot services in their business operations.",
  priceCommentList: [
    {
      id: 0,
      img: emch,
      description:
        "Our favorite feature is the Symptom checker, which summarizes a person's symptoms and provides directions to a doctor, and the wide variety of clinical information that can be distributed shows that the chatbot service is more than just an autoresponder.",
    },
    {
      id: 1,
      img: pn,
      description:
        "Consumers ask for information at any time of the day, and if we don't answer them, the chances of making a sale will decrease, consumers will be dissatisfied, and harm our business reputation. But we can solve this problem with the help of chatbot.",
    },
    {
      id: 2,
      img: itzone,
      description:
        "The chatbot team can swiftly adapt and make the necessary improvements and developments to meet the unique needs of our company and users in the fast-evolving digital landscape.",
    },
    {
      id: 3,
      img: euro,
      description:
        "We refer to chatbots as our 24/7 employees because they can share information with users through post comments, not just Messenger, and they can also assist with online travel bookings and information anytime, anywhere.",
    },
  ],
  faqHeader: "FREQUENTLY ASKED QUESTIONS",
  faq: [
    {
      id: 0,
      title: "How to create a bot?",
      description:
        "If you are accessing chatbot.mn for the first time, please sign up, and if you are a returning user, click login. One user can create five bots for free. Try the PRO package for a month, then automatically switch to the FREE plan.",
    },
    {
      id: 1,
      title: "How to connect a bot?",
      description:
        "Any person with Facebook page admin rights has the right to connect or disconnect the chatbot. Click the connect button => EDIT SETTINGS => login => choose your page => change all the settings to YES and click DONE.",
    },
    {
      id: 2,
      title: "What to do if token expired?",
      description: `ADMIN RIGHTS ON FACEBOOK PAGE, PASSWORD, ADMIN CHANGES RESULT IN token expired ERROR. So, you need to \n1. Log into the dashboard \n2. Go to the settings menu \n3. Press the clear button \n4. Press the Facebook connect button \n5. Choose your page and connect,`,
    },
    {
      id: 3,
      title: "How to renew your subscription? What happens if I don't renew?",
      description:
        "In the top right corner of the dashboard, you can click on the plans option. This will allow you to renew your bot or add extra paid services. After making your choice, you'll receive an invoice or can pay directly using a QR code to renew your bot.",
    },
  ],
  productHeader: "EXTRA FEATURES",
  mainFeature: {
    type: "Basic Service",
    description:
      "Chatbot LLC offers BOT CREATION AND DEVELOPMENT services, with the option to integrate with your internal systems to match your operational needs.",
  },
  subFeature: "Basic features",
  subDesc: [
    "Build menus according to the information flow",
    "Enter Frequently Asked Question",
    "Show product descriptions",
    "Divide users into several groups or segments",
    "Hubspot and erxes integrations",
    "Dedicated manager for that particular bot",
    "View analytics and do monitoring",
    "Process payments",
    "Send mass messages",
    "Conduct surveys",
    "Fill out inquiry forms",
    "Send custom messages etc. Many possibilities.",
  ],

  featureCTA:
    "We maintain ongoing communication with our clients and customers.",
  featureCTA2: [
    {
      title: "Empowerment training",
      description:
        "We adjust the training schedule to fit our clients needs and work capabilities. You can choose between weekdays 10:00 and 14:00. <br/> /Each training session lasts an hour and a half/",
    },
    {
      title: "Customer Service",
      description:
        "We are always with you to provide guidance and help with questions, improvements, entering additional information, and making changes related to chatbot.",
    },
  ],
  productHeader2: "Advantages",
  productGrid: [
    {
      id: 0,
      img: grid1,
      title: "Empowering training",
      description:
        "You can share news and information with specific users using blocks and automated responses and continue with auto-reply dialog.",
    },
    {
      id: 1,
      img: grid2,
      title: "Marketing and Sales channel",
      description:
        "You can attract customers and convert them into sales using a chatbot, one of the most effective tools of communication marketing.",
    },
    {
      id: 2,
      img: grid3,
      title: "Online ordering system",
      description:
        "Users can get information about products and services through the bot, add multiple items to a shopping cart, and access services like scheduling appointments or making table reservations.",
    },
    {
      id: 3,
      img: grid4,
      title: "Mass message",
      description:
        "You can send the information about short and long-term promotions, activations to your active bot subscribers through mass messaging.",
    },
    {
      id: 4,
      img: grid5,
      title: "User segmentation",
      description:
        "You can divide customers into different groups based on their behavior and product interests. This way, you can target your marketing efforts, purchases, and discounts more effectively.",
    },
    {
      id: 5,
      img: grid6,
      title: "Payment solutions",
      description:
        "By connecting to both domestic and international payment systems, you can not only sell your products online but also track the status of all orders from a single interface.",
    },
    {
      id: 6,
      img: grid7,
      title: "Report and data analysis",
      description:
        "You can check the chatbot's user statistics over time with easy-to-read graphs and tables. Additionally, you can access and analyze data for both today and the past period through a detailed report.",
    },
    {
      id: 7,
      img: grid8,
      title: "Customer feedback and complaint",
      description:
        "You can accept customer feedback and complaints 24/7, deliver necessary and relevant information without delay, support customer service activities, and improve product and service quality.",
    },
  ],
  button: {
    signin: "Log In",
    signup: "Sign Up",
    download: "Download Introduction",
    free: "Try it out",
    priceCTA: "Send a consulting request",
    dashboard: "Dashboard",
    logout: "Log out",
  },
  termTitle: "Terms of Service",
  termList: [
    {
      header: "General conditions",
      desc: [
        {
          d: "The Chatbot.mn customer answering system is an official service provided by “Chatbot” LLC, and the purpose of these terms of service is to regulate the rights and obligations of the parties when users use the system's services.",
          d1: [],
        },
        {
          d: "These Terms of Service will be valid from the time the user registers or creates an account in the system until it is closed.",
          d1: [],
        },
      ],
    },
    {
      header: "Points to note by the user",
      desc: [
        {
          d: "The Chatbot.mn system can connect with popular global messenger platforms like Facebook Messenger, WeChat, Whatsapp, Telegram, Viber, and Line. Currently, it's connected to the Facebook Messenger channel.",
          d1: [],
        },
        {
          d: "The information and conversations shared by the user through their self-created bot are considered private exchanges solely between the user and the messenger user. “Chatbot” LLC does not hold any legal responsibility for these interactions.",
          d1: [],
        },
        {
          d: "Chatbot LLC is entirely responsible for maintaining the confidentiality of all data generated within the Chatbot.mn system, which includes user accounts, bot information, and messenger user conversations. We commit not to disclose this information to third parties, except when required by law.",
          d1: [],
        },
        {
          d: "A user can make multiple bots on their account, but each bot can only be linked to one Facebook page.",
          d1: [],
        },
        {
          d: "To connect the bot they created to a Facebook page, the user needs admin rights for that page.",
          d1: [],
        },
        {
          d: "While using the system's created bot, the user is not allowed to engage in the following prohibited activities during conversations with messenger users. Includes:",
          d1: [
            "Please refrain from submitting or distributing fraudulent, defamatory, derogatory, insulting, or copyright-infringing information.",
            "Publishing confidential information of any organization or individual without proper permission",
            "Ask messenger users for private and personal financial secret information without permission",
            "Doing any act prohibited by the laws and regulations of Mongolia",
            "Use for election purposes is prohibited.",
          ],
        },
        {
          d: "The user-created bot within the Chatbot.mn system operates by exchanging information with the connected Facebook Messenger platform. Therefore, it is the user's responsibility to adhere strictly to the terms of service and privacy rules of the Facebook Messenger platform. If the user engages in an activity that does not comply with the terms of service of the Facebook Messenger platform, measures will be taken until the bot is deactivated.",
          d1: [],
        },
        {
          d: "The user is responsible for protecting the login password of the Chatbot.mn system and the user information from others, and is responsible for preventing any risks that may arise from the non-fulfillment of this responsibility. “Chatbot” LLC will not be responsible for the loss caused by this.",
          d1: [],
        },
        {
          d: "If the user believes that the privacy of their account lost to others, please change the password or contact us urgently at info@chatbot.mn or 7744-1616.",
          d1: [],
        },
      ],
    },
    {
      header: "Purchase and cancellation of paid services",
      desc: [
        {
          d: "The Chatbot.mn system provides both free features and paid services to users.",
          d1: [],
        },
        {
          d: "To access the paid feature, users are required to enable the paid service for their bot and sign a postpaid service agreement.",
          d1: [],
        },
        {
          d: "The pricing for the paid service can be found on the Chatbot.mn website and will depend on the monthly user count and chosen paid service.",
          d1: [],
        },
        {
          d: "The user can activate the paid service for each bot they create, and the service fee is calculated individually for each bot.",
          d1: [],
        },
        {
          d: "The billing for the service in a particular month will be automatically calculated on the 1st of the following month and sent to the user's registered email. Payment must be made by the 20th of the following month. Failure to make the payment by the 20th of the following month will result in the user losing access to the paid service features of the bot.",
          d1: [],
        },
        {
          d: "At any time, the user can cancel the paid service. If canceled, the user will receive invoices up to the service cancellation date.",
          d1: [],
        },
        {
          d: "Upon canceling paid services, the paid features will be disabled, but the free features will continue to be available.",
          d1: [],
        },
        {
          d: "The customer will automatically switch to the Free package when the paid package expires, and the chatbot service menus will be limited when switching. Advertisements will also be sent periodically during a user's access to the Chatbot Service.",
          d1: [],
        },
      ],
    },
    {
      header: "Disconnect messenger, delete chatbot and close account",
      desc: [
        {
          d: "The user can disconnect or connect the bot to a Facebook page at any time. In case of disconnection, the conversation history and messenger user data related to that Facebook messenger will be deleted. After disconnecting the bot from your Facebook page, you can choose to connect it to a new Facebook page.",
          d1: [],
        },
        {
          d: "The user can delete the bot they created. In this case, all information related to the bot, including message blocks, conversations, and user data, will be deleted.",
          d1: [],
        },
        {
          d: "If there is an unpaid invoice on the bot created by the user, the bot cannot be deleted.",
          d1: [],
        },
        {
          d: "The user has the possibility to close their account on the Chatbot.mn, and in this case, all bots and all information related to the bot will be deleted.",
          d1: [],
        },
        {
          d: "If there is any unpaid invoice on the user's account, the account cannot be closed.",
          d1: [],
        },
      ],
    },
    {
      header: "Other",
      desc: [
        {
          d: "These terms of service may undergo additional changes based on Chatbot LLC's operational updates. In the event of such changes, all users receiving the service will be notified in advance.",
          d1: [],
        },
        {
          d: 'The Chatbot.mn system is registered on the official site of the Communications Regulatory Commission in the name of "Chatbot" LLC. The copyright of the Chatbot.mn website and system is legally protected.',
          d1: [],
        },
      ],
    },
  ],
  privacyTitle: "Privacy Policy",
  privacyList: [
    {
      header: "General and Data Collection",
      desc: [
        {
          d: 'chatbot.mn smart chatbot creation platform (hereinafter referred to as "System") collects information of citizens and legal entities (hereinafter referred to as "Users") and their Facebook page users (hereinafter referred to as "Chatbot Users") in the following cases. It includes:',
          d1: [
            {
              d2: "A user can be an individual or a legal entity who accesses the chatbot.mn web system, creates an account, establishes a connection with their Facebook page with administrative rights, and installs a chatbot that automatically responds to chatbot users.",
              d3: [],
            },
            {
              d2: "A 'Chatbot User' refers to the end users who are connected with the auto-reply chatbot after clicking the “Send Message” button and other “Facebook Chat Plugin” on the User's connected Facebook page.",
              d3: [],
            },
            {
              d2: "When registering in the system, the user enters his email, last name, first name, phone number, and password, and the system encrypts the password created by the user using cryptographic methods without human intervention. The password is stored in the database, which is not readable.",
              d3: [],
            },
            {
              d2: "Also, the user logs in with their Facebook account to connect to their Facebook page, and at this time, the system collects the user's last name, first name, email address, date of birth, and gender with open settings, as well as Facebook App ID and Access Token information necessary to connect to the page is collected from Facebook with the user's permission.",
              d3: [],
            },
            {
              d2: "The chatbot user will register in the system after interacting with the autoresponder chatbot created by the user. Approved personal information, including last name, first name, email, phone number, birthday, age, gender, and 'Facebook PSID' (Page Scoped ID), will be stored in the system.",
              d3: [],
            },
            {
              d2: "When the user utilizes the system's payment and online shop features, it is necessary to enter and save the company's certificate, ID card copy, and account information in the system.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Terms of use of information",
      desc: [
        {
          d: "All information entered into the user and chatbot user system is the individual property of each and “Chatbot” LLC will not use it in any form other than the agreed conditions without the consent of the User and Chatbot User.",
          d1: [
            {
              d2: "For the User:",
              d3: [
                "chatbot.mn can check payment and order information for cloud services, send notifications related to them, and make service payments (with the User's permission).",
                "Provide customer service related to the User (with the User's permission)",
                "Carry out technical maintenance related to customers. (With User's permission)",
                "Deliver information about new products and discounts to users (With User's permission)",
                "To enhance the quality of the chatbot.mn service and introduce new features to the market, Chatbot LLC may collect and use non-confidential information from individuals and legal entities, employing statistical methods related to chatbots and chatbot-related information.",
              ],
            },
            {
              d2: "For the Chatbot User:",
              d3: [
                "The system will send messages using the “Facebook PSID” of the chatbot user to communicate with the chatbot user",
                "The user will utilize their chatbot user information to share details about their business, send information about products and services (referred to as 'Message Broadcasting'), or send mass messages within the guidelines approved by Facebook.",
                "The user will use the system to provide customer service and other services to its chatbot users.",
              ],
            },
          ],
        },
      ],
    },
    {
      header: "Sharing User Information",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Only in the event that information is required to be provided by the state authorities in accordance with the laws of Mongolia",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Storing User Data",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "User registration details, chatbot information, chatbot user information, conversation history, and all other data generated within the chatbot.mn system are securely stored in Chatbot LLC's Mobinet LLC data center, ensuring a high level of confidentiality and security.",
              d3: [],
            },
            {
              d2: "The user is responsible for not disclosing the login name and password to others.",
              d3: [],
            },
            {
              d2: "Chatbot LLC will not be responsible for any harm or damage caused to the user's chatbot due to lost access rights to others. Chatbot LLC advises that accessing information using the user's access rights is impossible.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Manage User Information",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Users can change their personal and company information at any time they want from the system dashboard.",
              d3: [],
            },
            {
              d2: "If the user disconnects their chatbot from the Facebook page, the autoresponder will disabled, and the information will be stored in the system until reactivated.",
              d3: [],
            },
            {
              d2: "When the user deletes their chatbot, all chatbot and chatbot user data will be permanently erased from the system and cannot be restored.",
              d3: [],
            },
            {
              d2: "If the user deactivates his account information, it will be stored in the system until it is deactivated again.",
              d3: [],
            },
            {
              d2: "If the user intends to delete their registration information, they should send a request to the email address info@chatbot.mn, and we will remove the registration information from the chatbot.mn system within 3-5 business days.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Privacy Policy Update",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "“Chatbot” LLC has the right to make changes to the Privacy Policy. In case changes are made to the Privacy Policy, it will be publicly published on the chatbot.mn website and the User has a responsibility to get acquainted.",
              d3: [],
            },
          ],
        },
      ],
    },
  ],
  //////////////////////////////////////////////////////////////
  heroStat: {
    col1: "Visitors to Chatbot",
    col2: "Developed virtual assistants and bots for brands",
    col3: "Businesses and customers",
  },
  products: [
    {
      id: 0,
      type: "Online Store",
      description:
        "Users can easily search for products they desire by category, and not only can they access detailed product information, but they can also make direct purchases.",
    },
    {
      id: 1,
      type: "Comment Reply",
      description:
        "Users who want to get information by commenting on a Facebook post will automatically respond to comments 24/7 and send detailed information to all of them via Messenger without wasting time.",
    },
    {
      id: 2,
      type: "Live Chat",
      description:
        "Due to the behavior of users, specific situations, requests, and their desire to receive services that suit them, customers have the need to contact an operator when they need information.",
    },
    {
      id: 3,
      type: "Delivery Service",
      description:
        "Customers make payments for their selected items, choose a delivery service, and have their orders sent to the specified address. It provides a comprehensive solution for the store feature.",
    },
    {
      id: 4,
      type: "Online Contract",
      description:
        "The information required for the contract is collected from the user through the inquire form and automatically uploaded to the contract draft, and the contract is ready and sent to both parties by email at the same time.",
    },
  ],
  productHeader3: "Team Collaboration",
  productSlide: [
    {
      id: 0,
      type: "MARKETING TEAM",
      title:
        "Effective marketing creates loyal customers and sustainable relationships.",
      description: [
        "Establish a relationship between the company and the customer.",
        "Enhance user experience",
        "Create a positive brand image",
        "Prepare consumers who have enough knowledge about the brand and love the brand",
      ],
    },
    {
      id: 1,
      type: "SALES TEAM",
      title: "Use technology to innovate business models and make sales.",
      description: [
        "Increase sales channel",
        "Collect potential customer information",
        "Support sales team activities",
        "Automate the sales process",
      ],
    },
    {
      id: 2,
      type: "SERVICE TEAM",
      title: "Satisfaction with the service motivates repeat purchases.",
      description: [
        "Provide necessary information to users 24/7",
        "Systematically resolve suggestions and complaints promptly",
        "Enhance the productivity of the customer service team",
        "Boost the number of repeat purchases by cultivating customer loyalty.",
      ],
    },
  ],
  footer: {
    title: "TRY CHATBOT FOR FREE",
    subtitle: "GET STARTED EASILY WITH YOUR CHATBOT IN YOUR NATIVE LANGUAGE",
    col1: "Chatbot",
    row1: "About Us",
    row2: "FAQ",
    row3: "Chatbot User Group",
    row4: "Contact Us",
    col2: "PRODUCT",
    row5: "Bot Development",
    row6: "Empowerment Training",
    row7: "Service Plans",
    row8: "Customer Service",
    col3: "HELP",
    row9: "Guide",
    row10: "Online Consultation",
    row11: "Manual",
    row12: "Template",
    copyright: "All rights reserved",
    year: "year",
  },
  about: {
    title: "ABOUT US",
    p1: "NSP LLC, formed by tech-savvy Mongolian youth with years of experience in the technology sector, began developing the Chatbot platform in 2019 and later transformed it into an independent entity, Chatbot LLC, transitioning from its startup origins.",
    p2: "It continues to automate the daily customer service of more than 3,500 companies engaged in government, private sector, and business activities and keeps introducing new technology opportunities to businesses.",
    row1Title: "MISSION",
    row2Title: "OUR VALUES",
    row3Title: "VISION",
    row1: "Highlighting the intelligence and solidarity of Mongolian youth on the international platform.",
    row2: ["Teamwork", "Vision", "Persistent labor"],
    row3: "We aim to advance the Chatbot platform, transforming it into a competitive product in the Southeast Asian and global markets, while expanding the distribution of our services through multiple channels.",
  },
  input: {
    email: "Email",
    pwd: "Password",
    lName: "Last Name",
    fName: "First Name",
    phone: "Phone",
    forgot: "Forgot Password?",
    remember: "Remember Me",
    signinif: "Already have an account? Sign in",
    signupif: "If you don't have an account", //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    signupifLink: "SIGN UP HERE.", //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    acceptTerm: 'CLICK "SIGN UP" TO CREATE AN ACCOUNT',
    acceptTerm2: "AGREE",
    forgotTitle: "ENTER YOUR REGISTERED EMAIL",
    forgotButton: "Reset Password",
    button: "Sign in with Facebook",
    privacy: "Privacy Policy.",
    term: "Terms and Conditions",
    label1: "Enter Email",
    label2: "Enter Password",
    label3: "Enter Last Name",
    label4: "Enter First Name",
    label5: "Enter Phone Number",
  },
  tutorial: {
    h2: "Learn with Us",
    h3: "Topics of Study",
    h4: "Chatbot Opportunity №",
    button: "Details",
    input: "Search...",
    templateTitle: "Related Templates",
    request: "Would you like to enroll in classroom training?",
    formName: "Your Name",
    formOrg: "Company",
    formDate: "Date / Available for enrollment at 10:00 on that day /",
    formEmail: "Your email address",
    formPhone: "Your contact phone number",
    formSubmit: "Submit Request",
    phoneLabel: "Phone number: ",
    phone: "77441616",
    addressLabel: "Our address: ",
    address:
      "Sukhbaatar District, Enkhtaivny Orgon Choloo, 15A/5, Ambassador Office, 1st floor 104, Ulaanbaatar, Mongolia 14200",
    paragraph:
      "In this course, you can get detailed information about CHATBOT services, features, and how to use them successfully from expert bot developers.",
    cards: [
      {
        id: 1,
        title: "Placing information",
        url: "https://www.youtube.com/embed/GBHuScg_5Mc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "You can regularly deliver all kinds of information about your products and services to your customers at any time of the week in many ways, such as images, text, video, files, links, etc.",
      },
      {
        id: 2,
        title: "Interactive Conversations",
        url: "https://www.youtube.com/embed/YhdgfHWzPAU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "By identifying keywords in people's questions, you can send customized answers and detailed information in response to those queries.",
      },
      {
        id: 3,
        title: "Frequently Asked Questions",
        url: "https://www.youtube.com/embed/YxUOWzF2FuI?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Users can generate FAQs and corresponding answers based on frequently asked questions by customers. The system will automatically respond to users, offering information quickly and efficiently.",
      },
      {
        id: 4,
        title: "Live Agent",
        url: "https://www.youtube.com/embed/qAoZvdaLWJw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The customer can stop the chatbot's automated information flow and interact with an operator to obtain precise details. Furthermore, the organization evaluates its operators on a scale of 1 to 5 stars based on user feedback, using this data to determine and improve the quality of its services.",
      },
      {
        id: 5,
        title: "Inquiry Form",
        url: "https://www.youtube.com/embed/OAgFNUG1NTg?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Chatbots can be used to design inquiry forms for gathering feedback about products and services, as well as various surveys and news in the form of chat interactions.",
      },
      {
        id: 6,
        title: "Web Form",
        url: "https://www.youtube.com/embed/Hth3BvrSgyo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "This feature allows the creation of an additional chat window for the purpose of questionnaire completion and data collection from users. When the webform is completed, the responses can be received via email, and the collected data can be downloaded from the dashboard as an Excel file.",
      },
      {
        id: 7,
        title: "Mass Message",
        url: "https://www.youtube.com/embed/YTgT3gQAFIA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Send standard messages, advertisements, and detailed information to all subscribers who have interacted with your chatbot, or to segmented groups within 24 hours.",
      },
      {
        id: 8,
        title: "Comment Reply",
        url: "https://www.youtube.com/embed/yaNh9Nteaqk?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "When users leave a comment on a Facebook post, they can automatically respond with two types of chat replies: \n1. Sending customized responses by recognizing keywords from user comments. \n2. Setting up a consistent single response regardless of what the user types. \n\nAdditionally, negative comments can be detected based on keywords stored in the conversation and deleted without being published.",
      },
      {
        id: 9,
        title: "Contact Data",
        url: "https://www.youtube.com/embed/eENdtu7CSis?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "All data, including contacts information, customer segments, chat history, query and webform results, reports, etc., is stored in the chatbot dashboard.",
      },
      {
        id: 10,
        title: "User Tag",
        url: "https://www.youtube.com/embed/e1dg50_w9GE?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Chatbot users can be segmented into tags based on their behavior and actions. This allows the creation of different groups for distributing and activating messages tailored to each segment.",
      },
      {
        id: 11,
        title: "Static Pages",
        url: "https://www.youtube.com/embed/57HuwrdPk4o?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "With the help of static pages, you can create additional windows in Messenger to show information.",
      },
      {
        id: 12,
        title: "System Integration",
        url: "https://www.youtube.com/embed/FXZgnKigRkQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "It is possible to integrate with systems such as Google sheet, Google calendar, Hubspot, etc., used by companies for their internal operations.",
      },
      {
        id: 13,
        title: "Analytics",
        url: "https://www.youtube.com/embed/Ez1eO5ce6E4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Chatbot user access and activity reports can be filtered by date, month, and day, and they can be downloaded as an Excel file.",
      },
      {
        id: 14,
        title: "Monitor",
        url: "https://www.youtube.com/embed/qfWTKhNH660?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The Monitor section shows information such as bot errors, unanswered user queries, and user actions within the system on a daily, monthly, and date-specific basis.",
      },
      {
        id: 15,
        title: "Shop 2.0",
        url: "https://www.youtube.com/embed/-7VLTCTJeg0?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "New Store 2.0 is your online shopping platform, enabling users to make payments through QPay and SocialPay, local payment systems specifically designed for shopping, all without leaving the messenger window. With our Store 2.0 feature, you can:\n1. Add products\n2. Apply discounts\n3. Provide informations for contacts\n4. Categorize your products\n5. Highlight featured products\n6. Show banners\n7. Create collections\n8. Set countdown timers\n9. Manage inventory\n10. Allow users to add products to their carts\n11. Configure shipping addresses\n12. Conduct online payments for product purchases.",
      },
      {
        id: 16,
        title: "Payment Integration",
        url: "https://www.youtube.com/embed/FpRjP7BcEJU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Chatbots are not just autoresponders managing communication between companies and users, but they can also integrate with local payment systems to facilitate payments for various products and services.",
      },
      {
        id: 17,
        title: "Delivery Service",
        url: "https://www.youtube.com/embed/ucvgziyd5uM?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Chatbot is automating and simplifying the process of delivering products to companies and users by establishing system connections with local delivery companies, offering a comprehensive solution within the online shopping Store 2.0 feature.",
      },
      {
        id: 18,
        title: "Order",
        url: "https://www.youtube.com/embed/JInmNgDLLwA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The order feature allows you to purchase products and services and make payments using QPAY and Social Pay. It is not limited to selling goods; you can also use it for various transactions such as training registration and payment, fundraising, registration verification, and more.",
      },
      {
        id: 19,
        title: "Create bots and link pages",
        url: "https://www.youtube.com/embed/rGJCF4wNNR4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Using the control panel, you can create new bots, view all existing bots, filter them by their active or inactive status, search by name, explore model bots suitable for your business, and give credentials access to users registered in the chatbot system to exchange information and make edits to your bots.",
      },
      {
        id: 20,
        title: "Message Block",
        url: "https://www.youtube.com/embed/kR57nSi3lOw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The Message Block feature serves as the main area for organizing messages for your customers, testing the flow of their operations by concatenating them, and preparing your bot.",
      },
      {
        id: 21,
        title: "Settings: General",
        url: "https://www.youtube.com/embed/r1Dq405xpKo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The General page of the settings consists of five sections: start, start question, allowed domains, persona, and bot settings. Within these settings, you can establish the first question users can ask, specify the allowed domains registered on the page, choose the bot's avatar or cover photo, and make various other adjustments.",
      },
      {
        id: 22,
        title: "Settings: Greetings",
        url: "https://www.youtube.com/embed/xIn51RFCXXc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The Greeting feature allows you to configure festival, daily, conversation starter, and farewell greeting messages.",
      },
      {
        id: 23,
        title: "Settings: Subscriber Entrance",
        url: "https://www.youtube.com/embed/FQqOZJ8l_XY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "This section enables you to send essential information and define the initial questions for subscribers accessing your company's chatbot for the first time by clicking 'Get started' through the 'Subscriber entrance'. Companies commonly use this feature to gather data, including users phone numbers and email addresses.",
      },
      {
        id: 24,
        title: "Settings: Main Menu",
        url: "https://www.youtube.com/embed/0nXYYo1kpig?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "The main menu is the button closest to the user, located in the lower right corner of the messenger window. It is an effective way for companies to showcase their most important information within this menu.",
      },
      {
        id: 25,
        title: "Users",
        url: "https://www.youtube.com/embed/xTIwo1l3A-g?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Using the user feature, users can access a list of all contacts registered in the company's chatbot or those who initiated the conversation by clicking 'Get started.' You can view the chat history of the 1 user and send replies.",
      },
      {
        id: 26,
        title: "User Attributes",
        url: "https://www.youtube.com/embed/vefrnuDnGyY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Attributes are the characteristics that represent users, and companies can gather the desired information from users and store it in the user attribute feature. This feature allows them to optimize marketing campaigns by grouping users based on common characteristics and sending mass messages to these groups.",
      },
      {
        id: 27,
        title: "User Group",
        url: "https://www.youtube.com/embed/vWOzDqq3cPQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "You can group registered users by tags and attributes and create segments to send mass messages.",
      },
      {
        id: 28,
        title: "Custom message",
        url: "",
        description:
          "The custom message feature is used to send personalized information at a specific time, such as completing incomplete customer actions, ensuring successful sales, recovering abandoned actions, Remind your products and services again, and more.\n\nA mass message is a general message broadcasted to multiple recipients simultaneously, whereas a custom message is tailored for an individual subscriber.",
      },
      {
        id: 29,
        title: "JSON API",
        url: "",
        description:
          "This is used when establishing an API connection to show variable information to users. For instance, it can provide industry information, timetables, product details, today's weather, the exchange rate of a selected currency, and more.",
      },
      {
        id: 30,
        title: "Content",
        url: "",
        description:
          "Using the content feature, you can bundle blocks into a single content unit and configure information that is accessible through multiple buttons simultaneously. Additionally, you can also prioritize the information to be delivered first to subscribers who have engaged with the created content.",
      },
    ],
    templates: [
      {
        id: 1,
        title: "E-commerce",
        img: ecommerce,
        button: "Show Template",
        desc: "Opportunity to sell your products and services online",
        list: [
          "Show product categories",
          "Show product types",
          "Provide detailed product information",
          "Offer discounted prices for products",
          "Shopping cart and purchase products",
          "Make an order, delivery and payment",
          "Set the period of the sale and promotion",
          "Track store stock levels",
        ],
      },
      {
        id: 2,
        title: "Beauty and health",
        img: hospital,
        button: "Show Template",
        desc: "Build loyal customers with chatbots",
        list: [
          "Beauty tips and information",
          "Professional beautician consultation",
          "Answer customer questions",
          "Offer suitable products and services",
          "Schedule appointments",
          "Conduct a beauty quiz",
          "Work hours",
          "Provide location information",
        ],
      },
      {
        id: 3,
        title: "Restaurants",
        img: restaurant,
        button: "Show Template",
        desc: "For restaurants, cafes, bars, pubs, karaoke, etc.",
        list: [
          "Introduce the menu",
          "Gather suggestions and requests",
          "Take food orders",
          "Take a table reservation",
          "Working hours",
          "Location information",
          "Offer special promotions",
          "Answer customer questions",
        ],
      },
      {
        id: 4,
        title: "Real Estate",
        img: realestate,
        button: "Show Template",
        desc: "Activation of various real estate information",
        list: [
          "Schedule meetings with agents",
          "Property advice and information",
          "Real estate listings",
          "All rental and sales information",
          "Register property listings",
          "Show exclusive listings",
          "Classify and distribute listings",
          "Registration of customer information",
        ],
      },
      {
        id: 5,
        title: "Education",
        img: education,
        button: "Show Template",
        desc: "Distribute information to students and automate enrollment",
        list: [
          "Answer frequently asked questions",
          "Provide necessary news and information",
          "Introduce enrollment programs and regulations",
          "Automate student registration",
          "Send course options and schedules",
          "Provide information about teachers and departments",
          "Provide information to freshmen",
          "Gather feedbacks",
        ],
      },
    ],
    notFound: "Page not found",
  },
};
